import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import Airtable from 'airtable';
import parse from 'html-react-parser';
import beautify from 'js-beautify';
import moment from 'moment';
import getConfig from 'next/config';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import publicIp from 'public-ip';
import qs from 'qs';
import { deviceDetect, deviceType } from 'react-device-detect';
import env from '/env';
import { getCountry, getCountryName } from '/imports/checkout/api/utils';
import { COMPANY_INFO, FREE_CHECKOUT_DOMAIN } from '/imports/core/api/constants';
import { replaceString } from '/imports/core/ui/helpers';
import {
  AVAILABLE_PLATFORM_LANGUAGES,
  BASIC_POSITION_COUNTRY,
  COMPLETE_POSITION_COUNTRY,
  LANGUAGES_CODES,
  REGION_MAP,
} from '/imports/generator/api/constants';
import { isCoverLetter } from '/imports/pdf/core/api/helpers';
import { apolloClient as client } from '/lib/initApollo';

const isDev = env.NODE_ENV === 'development';
const isProd = env.NODE_ENV === 'production';
const STORAGE_URL = 'https://storage.googleapis.com';

const {
  publicRuntimeConfig: { NODE_ENV },
} = getConfig();

// fetch user's ip and store it in local variable
let userIP;
const setUserIp = async () => {
  if (userIP) return;
  try {
    userIP = await publicIp.v4();
  } catch (e) {
    console.log('failed to get user IP');
  }
};
// self invoking(closure) function to set user's IP address on load
(() => {
  setUserIp();
})();
export const SkillBlocks = ['SKILLS', 'SOFT_SKILLS', 'HARD_SKILLS', 'CUSTOM_SKILLS_CATEGORY'];
export const getExpSkillsTagVars = () => marvelEmitter.getActiveVariant('exp_skills_tags_v1');

export const AiSelectWon = ['es', 'ar', 'tr', 'cs'];

const localPopupV2 = [
  'tr',
  'nl',
  'az',
  'ca',
  'tj',
  'sr',
  'el',
  'ms',
  'ru',
  'ar',
  'fr',
  'vi',
  'de',
  'pt-br',
  'cs',
  'es',
  'hr',
  'ko',
  'he',
  'ro',
  'hu',
  'bg',
  'it',
  'fi',
  'pl',
  'lv',
  'tl',
  'th',
  'lt',
  'id',
  'zh-cn',
  'sl',
  'ja',
  'ka',
  'sv',
  'kk',
  'pt',
  'et',
  'no',
];
export const expToogleLocaleToTest = ['da'];

export const isPopUpV2Active = (locale) =>
  localPopupV2.includes(locale) || marvelEmitter.getActiveVariant('exp_toogle_language') === 'popup_v2';

export const getPopupv2DesignVariant = () => {
  return marvelEmitter.getActiveVariant('exp_popupv2_design');
};

/**
 * Creates a new account
 */
export const createAccount = async (initialDevice, userDomain, email) => {
  const country = getCountryName();
  const obj = {
    initialDevice,
    userDomain,
    country,
  };
  if (email) obj.email = email;
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  };

  const response = await fetch(`${env.BASE_API}/auth/sign-up`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// Function to pack skills items
export function packItems(containerWidth, items) {
  // Sort items in decreasing order of width
  const sortedItems = items.slice().sort((a, b) => b.width - a.width);

  // Initialize rows array to store packed items
  const rows = [[]];

  sortedItems.forEach((item) => {
    let packed = false;

    // Try to fit the item into existing rows
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const rowWidth = row.reduce((acc, cur) => acc + cur.width, 0);

      // Check if the item fits in the current row
      if (rowWidth + item.width <= containerWidth) {
        row.push(item);
        packed = true;
        break;
      }
    }

    // If the item couldn't be packed into any existing row, create a new row
    if (!packed) {
      rows.push([item]);
    }
  });

  // Flatten the rows array to get the final packed items list
  return rows.reduce((acc, cur) => acc.concat(cur), []);
}

export const axeptioSettingsPerDomain = {
  atelierdecv: '6723687d55d01735b11fd281',
  'zivotopis-primjer': '672366cef09842e0b5fb9198',
  cvkoostamine: '672369769c5c21ebd384f0b6',
  theresumeranger: '672369a04c04d68616a3f44d',
  cvtoolspro: '672369c826a3d6abaae8e543',
  'cv-lite': '672131ba6215674dceeb3fc8',
  'cv-pavyzdys': '67236a6010891548e6483c4b',
  'creare-cv': '6716523604b8140a2ccda83d',
  'modelos-de-curriculo': '67236a7b3497ca8b15f7b00e',
  cvparaugs: '67236ac656f5ef53eab4df34',
  criarcv: '67236b8ac9ea8175ef0ee5a5',
  brillantcv: '67236bb13497ca8b15f7b010',
  'resume-nation': '67236bf056f5ef53eab4df37',
  cveksempel: '67236c09fd849df51d8951ce',
  cvminta: '67236c9748fe2d2a97d292a8',
  'cv-pohja': '67236cc9c9ea8175ef0ee5a7',
  'cv-skabelon': '67236ce856f5ef53eab4df39',
  'cv-voorbeeld': '67236cfc3c78a8117f5d82b0',
  'cv-wzor': '67236d1a9a46f09bf868809e',
  cvvzor: '672114d9f5ef494821445fe6',
  'xn--mxabdxcg5aeo5c': '67236d52fd849df51d8951d4',
  lebenslaufschreiben: '67236db40e1eca2bf6e20f92',
  modellocv: '67236de5c9ea8175ef0ee5aa',
  'skriva-cv': '67210f1497428ff3ef1b98a6',
  cvdeboss: '67236e30081360f424833150',
  mysmartcv: '67236e529211d7d768e1f58e',
  curriculumlisto: '67236e919211d7d768e1f590',
  'modeles-cv': '6721017ec1c7b5cb93c424ca',
  'resume-example': '67236ebd913822adaae9b76b',
  'ejemplos-curriculum': '67236ede716dc0b93468ee64',
  bestonlineresume: '67236efe716dc0b93468ee66',
  elegantcv: '67236f1b9211d7d768e1f593',
  resumedone: '67236f2e2fb4aea4d082ff1e',
};

/**
 * Gets a resume or cover letter
 */
export const getDocument = async (id, token, userId, source) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  // Allow admins to fetch user resumes
  if (userId) {
    fetchOptions.headers['x-user-id'] = userId;
  }

  const baseUrl = isCoverLetter(source) ? env.COVER_LETTERS_ENDPOINT : env.BASE_API;
  const path = isCoverLetter(source) ? 'cover-letters' : 'resume';
  const url = `${baseUrl}/${path}/${id}`;

  const response = await fetch(url, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }

  return response.json();
};

/**
 * Gets a resume by website slug
 */
export const getResumeBySlug = async (slug, token) => {
  let fetchOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (token) {
    fetchOptions = { ...fetchOptions, headers: { ...fetchOptions.headers, Authorization: `Bearer ${token}` } };
  }

  const path = token ? 'resume/get-resume-by-slug-private' : 'resume/get-resume-by-slug';
  const url = `${env.BASE_API}/${path}/${slug}`;
  const response = await fetch(url, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }

  return response.json();
};

/**
 * Export HTML as string.
 */

export const exportHTMLString = async (data, onePage, host, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (onePage === 'true') data.onePage = onePage;
  const fetchOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };
  const url = isBlogDomain(host) ? `/builder/en/api/export-html` : `/en/api/export-html`;
  const response = await fetch(url, fetchOptions);
  const text = await response.text();
  if (response.status !== 200) {
    throw new Error(text);
  }
  return text;
};

/**
 * Exports the resume via email.
 */

export const exportEmailHTML = async ({
  html,
  token,
  options,
  email,
  template,
  color,
  gender,
  isCoverLetter,
  isRTL,
  userId,
  resumeId,
}) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const fetchOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fileType: 'pdf',
      html,
      sendEmail: true,
      email,
      options,
      template,
      color,
      gender,
      isCoverLetter,
      isRTL,
      userId,
      resumeId,
    }),
  };

  const response = await fetch(`${env.BASE_API}/resume/export-pdf`, fetchOptions);

  return response;
};

/**
 * Exports the resume or cover letter HTML as PDF/Doc.
 */

export const exportHTML = async ({
  fileType = 'pdf',
  html,
  token,
  options,
  userId,
  template,
  color,
  gender,
  isCoverLetter,
  isSwapable,
  resumeId,
  isPublicRoute,
}) => {
  const headers = {
    ...(isPublicRoute ? {} : { Authorization: `Bearer ${token}` }),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  // Allow admins to override user ID and download user PDFs
  if (userId) {
    headers['x-user-id'] = userId;
  }

  const fetchOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify({
      fileType,
      html,
      options,
      template,
      color,
      gender,
      isCoverLetter,
      isRTL: isSwapable ? isSwapable : false,
      isGenderColorSame: true,
      userId: isCoverLetter ? null : userId,
      resumeId: isCoverLetter ? null : resumeId,
    }),
  };

  const url = isPublicRoute ? `${env.BASE_API}/resume/public-export-pdf` : `${env.BASE_API}/resume/export-pdf`;
  const response = await fetch(url, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }

  const arrayBuffer = await response.arrayBuffer();
  return Buffer.from(arrayBuffer);
};

/**
 * Exports the resume as Docx.
 */

export const exportDocx = async ({ token, link, userId }) => {
  let headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (userId) {
    headers['x-user-id'] = userId;
  }

  const fetchOptions = {
    method: 'GET',
    headers,
  };

  const response = await fetch(link, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }

  return response.blob();
};

/**
 * Exchanges the temporary token for a permanent login token.
 *
 * @param {String} tempToken
 */
export const verifyLogin = async (tempToken) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: tempToken }),
  };

  const response = await fetch(`${env.BASE_API}/auth/verify-login`, options);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

/**
 * Gets user refresh token.
 *
 * @param {String} token
 */
export const refreshToken = async (token) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  };

  const response = await fetch(`${env.BASE_API}/auth/refresh-token`, options);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

/**
 * Send email with link for cancel subscription.
 *
 * @param {String} email
 * @param {String} locale
 */
export const sendDowngradeEmail = async (email, locale, host) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(`${env.BASE_API}/support/send-downgrade-email`, {
    ...fetchOptions,
    body: JSON.stringify({
      email: email.toLowerCase(),
      origin: `${window.location.origin}${getRoute('', locale, false, host, isBlogDomain(host))}`,
      language: locale,
    }),
  });

  if (response.status > 399) {
    const errMsg = await response.text();
    throw new Error(errMsg);
  }
};

/**
 * Create a support ticket in zendesk.
 *
 * @param {Object} body
 */
export const createSupportTicket = async (body) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...body }),
  };

  const response = await fetch(`${env.BASE_API}/support/create-support-ticket`, options);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
};

export const cookieParser = (ctx) => {
  return parseCookies(ctx ? ctx : '');
};

/**
 * Logout deletes cookies and redirects home
 *
 * @param {Object} ctx
 * @param {String} locale
 * @param {Object} tracking
 */

export const deleteCookie = (cookieName, hostname) => {
  const destroyArgs = { path: '/', domain: `.${hostname}` };
  destroyCookie({}, cookieName, destroyArgs);
  destroyCookie({}, cookieName, { path: '/' });
};

export const logout = async (ctx, locale, tracking) => {
  const { trackEvent, resetTracking } = tracking || {};
  let domain = null;
  let hostname = null;
  if (typeof window !== 'undefined') {
    try {
      if (trackEvent && resetTracking) {
        trackEvent('logout');
        resetTracking();
      }
    } catch (error) {
      console.log('Error', error);
    }
    hostname = window.location.hostname;
  } else {
    const referrerHost = ctx.req?.headers && ctx.req.headers['x-blog-referrer'];
    const originalHost = ctx?.req?.headers?.host;
    const host = referrerHost || originalHost;
    hostname = host.split(':')[0];
  }

  deleteCookie('agent_client_id', hostname);
  deleteCookie('agent_client_name', hostname);
  deleteCookie('agent_client_email', hostname);
  deleteCookie('token', hostname);

  // localStorage.removeItem('email');

  if (client) {
    await client.cache.reset();
  }
  const host = getDomainFromHost(hostname);
  const defaultLanguage = getDefaultLanguage(host);
  const pathname = getRoute('/', locale || defaultLanguage, false, host, isBlogDomain(host));
  const queryString = { reset: true };
  if (ctx) {
    return ctx.res.redirect(`${pathname}?${qs.stringify(queryString)}`);
  }
  return (window.location.href = pathname);
};

/**
 * Gets the site URL for the environment.
 *
 * @param {string} nodeEnv - environment name
 */
export const getSiteURL = (nodeEnv = env.NODE_ENV) => {
  switch (nodeEnv) {
    case 'production':
      return 'https://resumedone.co';
    case 'staging':
      return 'https://resumedone-staging.com';
    default:
      return `http://localhost:${env.PORT}`;
  }
};

/**
 * Resolves a full path to a static file in the public directory.
 *
 * Currently there is no CDN, but using this helper will allow
 * us to implement a CDN without any signifcant code changes.
 *
 * @param {string} filePath - file path relative to the /static dir
 * @param {string} prefix - URL to prefix before /static
 */
export const staticFile = (filePath, prefix = getSiteURL()) => `${prefix}/static/${filePath}`;

/**
 * Resolves a full path to a static file in CDN.
 *
 * @param {string} filePath - file path relative to the /rd-assets dir
 */

export const cdnFile = (filePath, prefix = STORAGE_URL) => `${prefix}/rd-assets/${filePath}`;
export const cdnTemplateImage = (filePath, prefix = STORAGE_URL) => `${prefix}/${filePath}`;

/**
 * To be used to clean users localStorage and avoid
 * conflicts with future experiments (the names tend to be reused)
 */
export const removeExperiment = (experiment) => {
  const expSaved = localStorage.getItem(`PUSHTELL-${experiment}`) || [];
  if (!expSaved.length) return;
  return localStorage.removeItem(`PUSHTELL-${experiment}`);
};

/**
 * get the experiments current value/variant in local storage
 * can be used when getActiveVariant function cannot be called yet (before render, will return undefined)
 */
export const getActiveVariantStorage = (experiment) => {
  return localStorage?.getItem(`PUSHTELL-${experiment}`) || null;
};

// Checks if a user is a paid user
export const isPaidUser = (role = 'user') => ['admin', 'paid_user', 'agent'].includes(role.toLowerCase());

export const freeAccessForYear = (date) => {
  if (!date) return false;
  const today = new Date();
  if (new Date(date) > today) return true;
  return false;
};

// Get users device/browser language
export const getDeviceLanguage = () => {
  const locale = window.navigator.language || window.navigator.userLanguage;
  return moment.locale(locale);
};

export const getDeviceInformation = () => {
  const deviceDetails = deviceDetect();
  const deviceInfo = {
    OS: deviceDetails.os || deviceDetails.osName,
    type: deviceType,
  };

  if (deviceDetails.model) deviceInfo.model = deviceDetails.model;

  if (deviceDetails.vendor) deviceInfo.brand = deviceDetails.vendor;

  return deviceInfo;
};

export const trackIdentitySendinBlue = async (params) => {
  const url = `${env.BASE_API}/meta/sendin-blue/contact`;
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };
  const response = await fetch(url, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const suggestedSlug = (firstName, lastName, email) => {
  const randomNumber = Math.floor(Math.random() * 1000);
  const arr = [];
  if (firstName) arr.push(firstName);
  if (lastName) arr.push(lastName);
  email = email || '';
  if (!firstName && !lastName) {
    const [name] = email.split('@');
    arr.push(name);
  }
  arr.push(randomNumber);
  return arr.join('-').toLowerCase();
};

export const copyToClipboard = (slug) => {
  let tempInput = document.createElement('input');
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
  tempInput.value = env !== 'production' ? `https://sites.resumedone-staging.com?slug=${slug}` : `https://${slug}.com`;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};

export const getGender = async (firstName) => {
  if (!firstName) return;
  try {
    const url =
      env.NODE_ENV === 'production'
        ? `https://api.genderize.io?name=${firstName.trim().split(' ')[0]}&apikey=${env.GENDER_API_KEY}`
        : `https://api.genderize.io?name=${firstName.trim().split(' ')[0]}`;
    const response = await fetch(url).then((res) => res.json());
    return response.gender;
  } catch (e) {
    console.log('failed to fetch gender');
    return null;
  }
};
export const getDefaultLanguage = (domain) => {
  switch (domain) {
    case 'kazakhstan-cv':
      return LANGUAGES_CODES.KAZAKH;
    case 'cvyarat':
      return LANGUAGES_CODES.AZERBAIJANI;
    case 'kreatorcv':
      return LANGUAGES_CODES.POLISH;
    case 'zivotopis-primjer':
      return LANGUAGES_CODES.CROATIAN;
    case 'cvkoostamine':
      return LANGUAGES_CODES.ESTONIAN;
    case 'contoh-resume':
      return LANGUAGES_CODES.MALAYSIAN;
    case 'cv-hebrew':
      return LANGUAGES_CODES.HEBREW;
    case 'cv-pavyzdys':
      return LANGUAGES_CODES.LITHUANIAN;
    case 'creare-cv':
      return LANGUAGES_CODES.ROMANIAN;
    case 'modelos-de-curriculo':
      return LANGUAGES_CODES.PORTUGUESE;
    case 'cvparaugs':
      return LANGUAGES_CODES.LATVIAN;
    case 'cv-in-arabic':
      return LANGUAGES_CODES.MODERN_STANDARD_ARAB;
    case 'thai-resume':
      return LANGUAGES_CODES.THAI;
    case 'melhorcv':
    case 'criarcv':
      return LANGUAGES_CODES.BRAZIL;
    case 'cvvzor':
      return LANGUAGES_CODES.CZECH;
    case 'xn--mxabdxcg5aeo5c':
      return LANGUAGES_CODES.GREEK;
    case 'xn--e1aaaggwcwefd4n':
      return LANGUAGES_CODES.RUSSIAN;
    case 'cvforma':
      return LANGUAGES_CODES.GEORGIAN;
    case 'cvhazirlama':
      return LANGUAGES_CODES.TURKISH;
    case 'cv-voorbeeld':
      return LANGUAGES_CODES.DUTCH;
    case 'cv-pohja':
      return LANGUAGES_CODES.FINNISH;
    case 'cveksempel':
      return LANGUAGES_CODES.NORWEGIAN;
    case 'skriva-cv':
      return LANGUAGES_CODES.SWEDISH;
    case 'modellocv':
      return LANGUAGES_CODES.ITALIAN;
    case 'mau-cv':
      return LANGUAGES_CODES.VIETNAMESE;
    case 'lebenslaufschreiben':
      return LANGUAGES_CODES.GERMAN;
    case 'cv-skabelon':
      return LANGUAGES_CODES.DANISH;
    case 'cv-wzor':
    case 'kreatorcv':
      return LANGUAGES_CODES.POLISH;
    case 'cvminta':
      return LANGUAGES_CODES.HUNGARIAN;
    case 'cvmenarik':
      return LANGUAGES_CODES.INDONESIAN;
    case 'modeles-cv':
    case 'cvdeboss':
    case 'atelierdecv':
    case 'autoapply':
    case 'applycator':
      return LANGUAGES_CODES.FRENCH;
    case 'ejemplos-curriculum':
    case 'curriculumlisto':
    case 'cvenminutos':
    case 'cvexito':
      return LANGUAGES_CODES.SPANISH;
    default:
      return LANGUAGES_CODES.ENGLISH;
  }
};

export const getRoute = (path, locale, cropFirstSlash = false, host, isBlog) => {
  const isDefault = host ? getDefaultLanguage(host) === locale : getDefaultLanguage() === locale;
  const route = host === 'cvdeboss' || isDefault ? path : `/${locale}${path}`;
  const newRoute = cropFirstSlash && route.startsWith('/') ? route.slice(1) : route;
  return isBlog ? `/builder${newRoute}` : newRoute;
};

export const getLocaleFromPath = (path, isFrench, defaultLanguage) => {
  // const split = path?.split('/');
  // const pathValue = split[1] === 'builder' ? split[2] : split[1];
  const lang = path?.split('/')[1] || defaultLanguage || 'en';
  return lang.toLowerCase();
};

/**
 * Get resume by userId and resumeId
 */
export const getResumeById = async (userId, resumeId) => {
  let fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const url = `${env.BASE_API}/resume/${resumeId}/${userId}`;
  const response = await fetch(url, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const showResumeDonePage = (host) =>
  [
    'resumedone',
    'ejemplos-curriculum',
    'resume-example',
    'cv-lite',
    'cvtoolspro',
    'resumizeme',
    'theresumeranger',
    'bestonlineresume',
    'cvmenarik',
    'cvminta',
    'lebenslaufschreiben',
    'mau-cv',
    'cv-skabelon',
    'cv-wzor',
    'skriva-cv',
    'modellocv',
    'cveksempel',
    'cv-pohja',
    'cvforma',
    'cvhazirlama',
    'cv-voorbeeld',
    'cvvzor',
    'xn--mxabdxcg5aeo5c',
    'xn--e1aaaggwcwefd4n',
    'thai-resume',
    'criarcv',
    'cv-in-arabic',
    'cvparaugs',
    'modelos-de-curriculo',
    'creare-cv',
    'cv-pavyzdys',
    'cv-hebrew',
    'contoh-resume',
    'resume-nation',
    'cvkoostamine',
    'zivotopis-primjer',
    'kreatorcv',
    'cvyarat',
    'kazakhstan-cv',
  ].includes(host);

// remove this function when all blog have own faq page
export const getFAQUrl = (host) => {
  switch (host) {
    case 'cv-olustur':
      return 'https://help.cv-olustur.com/';
    case 'resumetiger':
      return 'https://help.resumetiger.app/';
    case 'quantumresume':
      return 'https://help.quantumresume.app/';
    case 'cvelevator':
      return 'https://help.cvelevator.app/';
    case 'cvenminutos':
      return 'https://help.cvenminutos.com/';
    case 'dreamresume':
      return 'https://help.dreamresume.app/';
    case 'melhorcv':
      return 'https://help.melhorcv.com/';
    case 'atelierdecv':
      return 'https://help.atelierdecv.com/';
    case 'cvexito':
      return 'https://help.cvexito.com/';
    case 'kazakhstan-cv':
      return 'https://help.kazakhstan-cv.com/';
    case 'cvyarat':
      return 'https://help.cvyarat.app/';
    case 'cvkoostamine':
      return 'https://help.cvkoostamine.com/';
    case 'theresumeranger':
      return 'https://help.theresumeranger.com/';
    case 'resumizeme':
      return 'https://help.resumizeme.com/';
    case 'cvtoolspro':
      return 'https://help.cvtoolspro.com/';
    case 'cv-lite':
      return 'https://help.cv-lite.com/';
    case 'mysmartcv':
      return 'https://help.mysmartcv.co/';
    case 'resume-example':
      return 'https://help.resume-example.com/';
    case 'ejemplos-curriculum':
      return 'https://help.ejemplos-curriculum.com/';
    case 'modeles-cv':
      return 'https://help.modeles-cv.fr/';
    case 'cvmenarik':
      return 'https://help.cvmenarik.com/';
    case 'mau-cv':
      return 'https://help.mau-cv.com/';
    case 'cvminta':
      return 'https://help.cvminta.com/';
    case 'lebenslaufschreiben':
      return 'https://help.lebenslaufschreiben.com/';
    case 'cv-wzor':
      return 'https://help.cv-wzor.com/';
    case 'cv-skabelon':
      return 'https://help.cv-skabelon.com/';
    case 'cv-pohja':
      return 'https://help.cv-pohja.com/';
    case 'cveksempel':
      return 'https://help.cveksempel.com/';
    case 'modellocv':
      return 'https://help.modellocv.com/';
    case 'skriva-cv':
      return 'https://help.skriva-cv.com/';
    case 'cv-voorbeeld':
      return 'https://help.cv-voorbeeld.com/';
    case 'cvhazirlama':
      return 'https://help.cvhazirlama.cool/';
    case 'cvvzor':
      return 'https://help.cvvzor.com/';
    case 'xn--e1aaaggwcwefd4n':
      return 'https://help.резюмепример.com/';
    case 'thai-resume':
      return 'https://help.thai-resume.com/';
    case 'modelos-de-curriculo':
      return 'https://help.modelos-de-curriculo.com/';
    case 'cv-in-arabic':
      return 'https://help.cv-in-arabic.com/';
    case 'xn--mxabdxcg5aeo5c':
      return 'https://help.βιογραφικο.com/';
    case 'cvparaugs':
      return 'https://help.cvparaugs.com/';
    case 'criarcv':
      return 'https://help.criarcv.com/';
    case 'creare-cv':
      return 'https://help.creare-cv.com/';
    case 'cv-pavyzdys':
      return 'https://help.cv-pavyzdys.com/';
    case 'cv-hebrew':
      return 'https://help.cv-hebrew.com/';
    case 'elegantcv':
      return 'https://help.elegantcv.app/';
    case 'bestonlineresume':
      return 'https://help.bestonlineresume.com/';
    case 'cvdeboss':
      return 'https://help.cvdeboss.com/';
    case 'contoh-resume':
      return 'https://help.contoh-resume.com/';
    case 'cvforma':
      return 'https://help.cvforma.com/';
    case 'resume-nation':
      return 'https://help.resume-nation.com/';
    default:
      return 'https://help.resumedone.co/';
  }
};

export const getFAQRedirectionUrl = (host, locale) => {
  const localeString = locale === getDefaultLanguage(host) ? '' : locale;
  return getFAQUrl(host) + localeString;
};

export const RESUMEDONE_BLOGS = [
  'resume-example',
  'modeles-cv',
  'blog.resumedone-staging',
  'ejemplos-curriculum',
  'cveksempel',
  'cvforma',
  'cvhazirlama',
  'cvminta',
  'cv-pohja',
  'cv-skabelon',
  'cv-voorbeeld',
  'cv-wzor',
  'cvmenarik',
  'cvvzor',
  'lebenslaufschreiben',
  'mau-cv',
  'modellocv',
  'skriva-cv',
  'xn--mxabdxcg5aeo5c',
  'xn--e1aaaggwcwefd4n',
  'criarcv',
  'thai-resume',
  'cv-in-arabic',
  'cvparaugs',
  'modelos-de-curriculo',
  'creare-cv',
  'cv-pavyzdys',
  'cv-hebrew',
  'contoh-resume',
  'cvkoostamine',
  'zivotopis-primjer',
  'kreatorcv',
  'cvyarat',
  'kazakhstan-cv',
  'cvitaliano',
];

export const isBlogDomain = (hostname) => {
  return RESUMEDONE_BLOGS.some((blogDomain) => {
    return hostname.includes(blogDomain);
  });
};

export const isJobTrackEnable = () => {
  const isJobTrack = marvelEmitter.getActiveVariant('job-tracking') === 'with_job_track';
  return isJobTrack;
};

export const getJobTrackRoute = (host, route, isMobile) => {
  const isHeader = isJobTrackEnable();
  const routes = {
    resumes: {
      normal: '/resumes',
      jobtrack: isHeader && !isMobile ? '/resumes' : '/app?template=dashboard',
      jobtrackAspath: isHeader && !isMobile ? '/resumes' : '/app/dashboard',
    },
    coverletters: {
      normal: '/cover-letters',
      jobtrack: isHeader && !isMobile ? '/cover-letters' : '/app?template=dashboard',
      jobtrackAspath: isHeader && !isMobile ? '/cover-letters' : '/app/dashboard',
    },
  };
  if (isJobTrackEnable()) {
    return { href: routes[route]?.jobtrack, as: routes[route]?.jobtrackAspath };
  } else {
    return { href: routes[route]?.normal, as: routes[route]?.normal };
  }
};

export const activeCookiesDomain = ['cvvzor', 'modeles-cv', 'zivotopis-primjer', 'skriva-cv'];

export const cookiesHubID = {
  atelierdecv: '430b7e47',
  bestonlineresume: '35bbb28f',
  brillantcv: '7683c284',
  buildmycv: '960f9c28',
  'contoh-resume': 'b8ea92b4',
  'creare-cv': 'dd6904da',
  criarcv: '0624a31b',
  curriculumlisto: '0ee21b9a',
  'cv-hebrew': '8da2c134',
  'cv-in-arabic': '4bd530f4',
  'cv-lite': '1916cb4d',
  'cv-pavyzdys': '61b9dae0',
  'cv-pohja': '09484a95',
  'cv-skabelon': '491d50cf',
  'cv-voorbeeld': '75d600a8',
  'cv-wzor': '4781af5e',
  cvdeboss: '9df6feef',
  cveksempel: '29aedea7',
  cvenminutos: '28c9e921',
  cvexito: 'd0a64001',
  cvforma: 'b0fd1892',
  cvhazirlama: '941debaa',
  cvkoostamine: 'c793b21a',
  cvmenarik: '3752ac9d',
  cvminta: '9821e324',
  cvparaugs: '736099f0',
  cvtoolspro: 'bc7c9ab9',
  cvvzor: '9671b3cc',
  cvyarat: '68408f16',
  dreamresume: '9f52638e',
  'ejemplos-curriculum': 'b8bfb311',
  elegantcv: '87cf77da',
  'kazakhstan-cv': '6bc7eb59',
  kreatorcv: '0977b608',
  lebenslaufschreiben: 'f51b803d',
  'mau-cv': 'c9ad7b97',
  melhorcv: 'bd318341',
  'modeles-cv': '449b2835',
  modellocv: '4e0d9ffe',
  'modelos-de-curriculo': '6fe0128c',
  mysmartcv: '29ff31a7',
  quantumresume: 'f59e557e',
  'resume-example': '344b5470',
  'resume-nation': '680a388e',
  resumedone: 'e0fb0d76',
  resumetiger: '6c9ad719',
  resumizeme: '385b7f92',
  'skriva-cv': '6db10dae',
  'thai-resume': '0c3840d7',
  theresumeranger: 'd1930bd4',
  verycoolcv: 'a81d30a0',
  'xn--e1aaaggwcwefd4n': '84ad268d',
  'xn--mxabdxcg5aeo5c': '44d308a1',
  'zivotopis-primjer': '4fa83d4e',
};

export const getGTMID = (host) => {
  switch (host) {
    case 'cvtoolspro':
      return 'G-T2K5NFC5YK';
    case 'resumizeme':
      return 'G-RBX6MKS9M0';
    case 'theresumeranger':
      return 'G-M9KVYGYKG5';
    case 'resumedone':
      return 'G-L5HSMFDLK4';
    case 'bestonlineresume':
      return 'G-ZW0VWT7S5N';
    case 'elegantcv':
      return 'G-8WF9ZEQ0NM';
    case 'mysmartcv':
      return 'G-9F54QLRV16';
    case 'buildmycv':
      return 'G-EGVM25WWHR';
    case 'resume-nation':
      return 'G-2V7F99J4P3';
    case 'brillantcv':
      return 'G-883S417FMB';
    case 'cvdeboss':
      return 'G-N2MRYGPJWT';
    case 'resume-example':
      return 'G-S0KZTBSJB3';
    case 'modeles-cv':
      return 'G-ERXESN28TY';
    case 'ejemplos-curriculum':
      return 'G-582MZ5LW1F';
    case 'mau-cv':
      return 'G-C8Y3MDVBYM';
    case 'cv-pohja':
      return 'G-38XS4QYTWB';
    case 'cv-skabelon':
      return 'G-617HT5LXVH';
    case 'cv-voorbeeld':
      return 'G-EJB38EKEXN';
    case 'cvminta':
      return 'G-V78X7KC5JL';
    case 'cv-wzor':
      return 'G-7N2EZ0ZDNG';
    case 'cveksempel':
      return 'G-MV1PTG45X4';
    case 'cvforma':
      return 'G-S0MF35VVKE';
    case 'cvmenarik':
      return 'G-XZEB7B9W5D';
    case 'cvvzor':
      return 'G-F1899C80WW';
    case 'lebenslaufschreiben':
      return 'G-JCM0PH0C4R';
    case 'modellocv':
      return 'G-9EXQ62MW9Q';
    case 'skriva-cv':
      return 'G-8L1PQ1DBBW';
    case 'cvhazirlama':
      return 'G-54DKLJBS51';
    case 'thai-resume':
      return 'G-BQ211MYLCZ';
    case 'xn--mxabdxcg5aeo5c':
      return 'G-JZC23MT8HR';
    case 'xn--e1aaaggwcwefd4n':
      return 'G-29Y68HWM7R';
    case 'criarcv':
      return 'G-1CL9W7GRQQ';
    case 'cvparaugs':
      return 'G-VP0YYHWVMQ';
    case 'modelos-de-curriculo':
      return 'G-QMFCRERJ21';
    case 'creare-cv':
      return 'G-K1CHFV30D0';
    case 'cv-pavyzdys':
      return 'G-DES1Y74HSQ';
    case 'cv-hebrew':
      return 'G-THV76S5BEV';
    case 'contoh-resume':
      return 'G-NE2HQ8HEZQ';
    case 'cv-in-arabic':
      return 'G-VLR3G0VCFJ';
    case 'cv-lite':
      return 'G-GFFZQ6JX4R';
    default:
      return null;
  }
};

export const getPinterestVerification = (host) => {
  switch (host) {
    case 'bestonlineresume':
      return 'd4502b8d01b7e07a119a2c0c74d4906f';
    default:
      return null;
  }
};

export const getPinterestID = (host) => {
  switch (host) {
    case 'bestonlineresume':
      return '2612722757115';
    default:
      return null;
  }
};

export const getBTMID = (host) => {
  switch (host) {
    case 'bestonlineresume':
    case 'cvtoolspro':
    case 'modeles-cv':
    case 'cvdeboss':
      return '343058944';
    default:
      return '187011007';
  }
};

export const isRemarketingHost = (host) =>
  ['cvdeboss', 'modeles-cv', 'bestonlineresume', 'cv-skabelon', 'resume-example'].includes(host);

export const getReMarketingConv = (host) => {
  const Conv = {
    cvdeboss: {
      id: 'AW-652544004',
      heading: 'nj00CL2S_-8DEISQlLcC',
      finalize: 'ts9NCKOat_ADEISQlLcC',
      checkout: 'YWw3CPaur_ADEISQlLcC',
      payment: 'm0ZjCJCxr_ADEISQlLcC',
    },
    'modeles-cv': {
      id: 'AW-652544004',
      heading: 'l1QLCIbFlvEDEISQlLcC',
      finalize: '0ykwCKrSxvEDEISQlLcC',
      checkout: 'WzJ0CJGR0PEDEISQlLcC',
      payment: 'H7TbCPDelvEDEISQlLcC',
    },
    bestonlineresume: {
      id: 'AW-652544004',
      heading: 'nnJkCO3ZxvEDEISQlLcC',
      finalize: 'eS0dCIPmlvEDEISQlLcC',
      checkout: 'AYSlCNHmlvEDEISQlLcC',
      payment: 'r4HvCIec0PEDEISQlLcC',
    },
    'cv-skabelon': {
      id: 'AW-652544004',
      heading: 'fyJaCIDhxvEDEISQlLcC',
      finalize: 'oT2PCLme0PEDEISQlLcC',
      checkout: '1F7fCLDslvEDEISQlLcC',
      payment: 'rsjYCOah0PEDEISQlLcC',
    },
    'resume-example': {
      id: 'AW-652544004',
      heading: 'aQ8GCJrxlvEDEISQlLcC',
      finalize: 'hEBjCLXjxvEDEISQlLcC',
      checkout: 'Q_EaCMPzlvEDEISQlLcC',
      payment: 'U2QLCOel0PEDEISQlLcC',
    },
  };
  return Conv[host];
};

export const getADMID = (host) => {
  switch (host) {
    case 'resume-nation':
    case 'elegantcv':
    case 'contoh-resume':
    case 'cv-lite':
    case 'resumizeme':
    case 'modelos-de-curriculo':
    case 'theresumeranger':
    case 'applycator':
    case 'autoapply':
      return 'AW-10828919399';
    case 'cvparaugs':
      return 'AW-11047631111';
    default:
      return 'AW-652544004';
  }
};

export const getRdtID = (host) => {
  switch (host) {
    case 'cvtoolspro':
    case 'theresumeranger':
      return 'a2_g9wtb7b3igym';
    default:
      return null;
  }
};

export const getConvADM = (host) => {
  switch (host) {
    case 'resume-nation':
    case 'elegantcv':
    case 'contoh-resume':
    case 'cv-lite':
    case 'resumizeme':
    case 'modelos-de-curriculo':
    case 'theresumeranger':
      return 'Ox1RCLTF0uADEOfk0Kso';
    case 'cvparaugs':
      return 'Z6AHCO6D74UYEIfy9ZMp';
    default:
      return 'zkLOCNmTpu8CEISQlLcC';
  }
};
export const removeAccents = (_str, rmPunctuation = true, lowerCase = true) => {
  let str = _str || ''?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');
  if (rmPunctuation) {
    str = str?.replace(/[_,.!?;-]+/g, ' ');
  }
  if (lowerCase) {
    str = str?.toLowerCase();
  }
  return str?.replace(/\s+/g, ' ').replace(/\n\n/g, '\n');
};
export const getDomainFromHost = (url) => {
  switch (true) {
    case url.toLowerCase().includes('cv-olustur'):
      return 'cv-olustur';
    case url.toLowerCase().includes('applycator'):
      return 'applycator';
    case url.toLowerCase().includes('autoapply'):
      return 'autoapply';
    case url.toLowerCase().includes('resumetiger'):
      return 'resumetiger';
    case url.toLowerCase().includes('quantumresume'):
      return 'quantumresume';
    case url.toLowerCase().includes('cvelevator'):
      return 'cvelevator';
    case url.toLowerCase().includes('cvenminutos'):
      return 'cvenminutos';
    case url.toLowerCase().includes('dreamresume'):
      return 'dreamresume';
    case url.toLowerCase().includes('melhorcv'):
      return 'melhorcv';
    case url.toLowerCase().includes('atelierdecv'):
      return 'atelierdecv';
    case url.toLowerCase().includes('cvexito'):
      return 'cvexito';
    case url.toLowerCase().includes('verycoolcv'):
      return 'verycoolcv';
    case url.toLowerCase().includes('kazakhstan-cv'):
      return 'kazakhstan-cv';
    case url.toLowerCase().includes('cvyarat'):
      return 'cvyarat';
    case url.toLowerCase().includes('kreatorcv'):
      return 'kreatorcv';
    case url.toLowerCase().includes('zivotopis-primjer'):
      return 'zivotopis-primjer';
    case url.toLowerCase().includes('cvkoostamine'):
      return 'cvkoostamine';
    case url.toLowerCase().includes('theresumeranger'):
      return 'theresumeranger';
    case url.toLowerCase().includes('cvtoolspro'):
      return 'cvtoolspro';
    case url.toLowerCase().includes('resumizeme'):
      return 'resumizeme';
    case url.toLowerCase().includes('cv-lite'):
      return 'cv-lite';
    case url.toLowerCase().includes('contoh-resume'):
      return 'contoh-resume';
    case url.toLowerCase().includes('cv-hebrew'):
      return 'cv-hebrew';
    case url.toLowerCase().includes('cv-pavyzdys'):
      return 'cv-pavyzdys';
    case url.toLowerCase().includes('creare-cv'):
      return 'creare-cv';
    case url.toLowerCase().includes('modelos-de-curriculo'):
      return 'modelos-de-curriculo';
    case url.toLowerCase().includes('cvparaugs'):
      return 'cvparaugs';
    case url.toLowerCase().includes('cv-in-arabic'):
      return 'cv-in-arabic';
    case url.toLowerCase().includes('thai-resume'):
      return 'thai-resume';
    case url.toLowerCase().includes('criarcv'):
      return 'criarcv';
    case url.toLowerCase().includes('brillantcv'):
      return 'brillantcv';
    case url.toLowerCase().includes('resume-nation'):
      return 'resume-nation';
    case url.toLowerCase().includes('cveksempel'):
      return 'cveksempel';
    case url.toLowerCase().includes('cvforma'):
      return 'cvforma';
    case url.toLowerCase().includes('cvhazirlama'):
      return 'cvhazirlama';
    case url.toLowerCase().includes('cvminta'):
      return 'cvminta';
    case url.toLowerCase().includes('cv-pohja'):
      return 'cv-pohja';
    case url.toLowerCase().includes('cv-skabelon'):
      return 'cv-skabelon';
    case url.toLowerCase().includes('cv-voorbeeld'):
      return 'cv-voorbeeld';
    case url.toLowerCase().includes('cv-wzor'):
      return 'cv-wzor';
    case url.toLowerCase().includes('cvmenarik'):
      return 'cvmenarik';
    case url.toLowerCase().includes('cvvzor'):
      return 'cvvzor';
    case url.toLowerCase().includes('xn--mxabdxcg5aeo5c'):
      return 'xn--mxabdxcg5aeo5c';
    case url.toLowerCase().includes('xn--e1aaaggwcwefd4n'):
      return 'xn--e1aaaggwcwefd4n';
    case url.toLowerCase().includes('lebenslaufschreiben'):
      return 'lebenslaufschreiben';
    case url.toLowerCase().includes('mau-cv'):
      return 'mau-cv';
    case url.toLowerCase().includes('modellocv'):
      return 'modellocv';
    case url.toLowerCase().includes('skriva-cv'):
      return 'skriva-cv';
    case url.toLowerCase().includes('cvdeboss'):
      return 'cvdeboss';
    case url.toLowerCase().includes('mysmartcv'):
      return 'mysmartcv';
    case url.toLowerCase().includes('buildmycv'):
      return 'buildmycv';
    case url.toLowerCase().includes('curriculumlisto'):
      return 'curriculumlisto';
    case url.toLowerCase().includes('modeles-cv'):
      return 'modeles-cv';
    case url.toLowerCase().includes('resume-example'):
      return 'resume-example';
    case url.toLowerCase().includes('ejemplos-curriculum'):
      return 'ejemplos-curriculum';
    case url.toLowerCase().includes('bestonlineresume'):
      return 'bestonlineresume';
    case url.toLowerCase().includes('elegantcv'):
      return 'elegantcv';
    case url.toLowerCase().includes('resumedone'):
    default:
      return 'resumedone';
  }
};
export const LANDING_TITLE = [
  {
    locale: 'ar',
    variant: [{ name: 'var_A', value: `أنشئ سيرة ذاتية لا تقتصر على كونها “شوهدت فقط” من قبل أصحاب العمل.` }],
  },
  {
    locale: 'tr',
    variant: [
      {
        name: 'var_B',
        value: `Yapay zeka sayesinde CV'niz o kadar ikna edici olacak ki, anneniz bile sizi iş için yeterli görecek!`,
      },
    ],
  },
  {
    locale: 'ms',
    variant: [
      { name: 'var_A', value: `Buat CV yang tidak akan berakhir dalam mod "dilihat" oleh perekrut.` },
      {
        name: 'var_B',
        value: `AI yang menjadikan CV anda begitu meyakinkan sehinggakan ibu anda pun akan menganggap anda layak!`,
      },
    ],
  },
];
export const PONTON_MEDIA_HOST = ['cvmenarik', 'bestonlineresume', 'elegantcv'];

export const FEF_HOST = [
  'cv-lite',
  'xn--mxabdxcg5aeo5c',
  'ejemplos-curriculum',
  'cv-pohja',
  'cv-skabelon',
  'cv-voorbeeld',
  'lebenslaufschreiben',
  'creare-cv',
  'cv-pavyzdys',
  'mysmartcv',
  'resume-example',
  'cvvzor',
  'skriva-cv',
  'criarcv',
  'thai-resume',
  'zivotopis-primjer',
  'cvkoostamine',
  'verycoolcv',
];

export const SG_DESCRIPTOR_DOMAINS = [
  'modelos-de-curriculo',
  'modellocv',
  'cvkoostamine',
  'cv-hebrew',
  'cvforma',
  'verycoolcv',
];
export const TRDESCRIPTOR_DOMAINS = ['cv-lite', 'resume-example'];
export const getFSDescriptorByHost = (host) => {
  switch (host) {
    case 'resumedone':
      return `FS *${getDomainName(host)}`;
    default:
      return `FS ${host}*s`;
  }
};

export const getSGDescriptorByHost = (host) => {
  switch (host) {
    case 'verycoolcv':
      return 'SG*verycoolcv.com';
    case 'modelos-de-curriculo':
      return 'SG*modelosdecurriculo';
    case 'modellocv':
    case 'cvkoostamine':
    case 'cv-hebrew':
    case 'cvforma':
    default:
      return `SG-${getDomainName(host)}`;
  }
};

//LIST OF DOMAINS THAT HAVE SOME TEMPORARY CHANGE (T&C,PP,DESCRIPTOR)
export const DOMAINS_CHANGE = [
  'cv-lite',
  'criarcv',
  'cv-pohja',
  'skriva-cv',
  'cv-pavyzdys',
  'xn--mxabdxcg5aeo5c',
  'creare-cv',
  'mysmartcv',
  'resume-example',
  'cv-voorbeeld',
  'cvvzor',
  'cv-skabelon',
  'lebenslaufschreiben',
  'ejemplos-curriculum',
  'thai-resume',
  'zivotopis-primjer',
];

export const TALENTREE_LIMITED_HOST = ['quantumresume', 'cv-olustur'];

export const KUKARIO_LIMITED_HOST = [
  'cvtoolspro',
  'modelos-de-curriculo',
  'modellocv',
  'cv-hebrew',
  'cvforma',
  'atelierdecv',
  'cvminta',
  'contoh-resume',
  'cvyarat',
  'kreatorcv',
  'kazakhstan-cv',
  'mau-cv',
  'cvexito',
  'melhorcv',
];

export const DICOLARE_LIMITED_HOST = [];

export const OZUARA_LIMITED_HOST = ['theresumeranger', 'cvelevator', 'modeles-cv'];

export const getCompanyRegistrationOffice = (host) => {
  const registrationOffice = COMPANY_INFO.find(
    (item) => item.domains && item.domains.includes(host),
  )?.registrationOffice;
  if (registrationOffice) return registrationOffice;
  else return 'Irish Companies Registration Office (CRO)';
};

export const getCompanyCountryAdjective = (host) => {
  const countryAdjective = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host))?.countryAdjective;
  if (countryAdjective) return countryAdjective;
  else return 'Irish';
};
export const getCompanyNumber = (host) => {
  const companyNumber = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host))?.companyNumber;
  if (companyNumber) return companyNumber;
  else return '644795';
};

export const getCompanyNameByHost = (host) => {
  const CompanyName = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host))?.companyName;
  if (CompanyName) return CompanyName;
  else return 'GREAT PONTON COMPANY';
};

export const getCompanyCountryByHost = (host) => {
  const CompanyCountry = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host))?.country;
  if (CompanyCountry) return CompanyCountry;
  else return 'Dublin';
};

export const getLandingAddress = (host) => {
  const CompanylandingAddress = COMPANY_INFO.find(
    (item) => item.domains && item.domains.includes(host),
  )?.companyLandingAddress;
  if (CompanylandingAddress) return CompanylandingAddress;
  else return 'Ormond Building, 31-36 Ormond Quay Upper, Dublin 7, D07 EE37';
};

export const getContactNumber = (host, slug, t) => {
  const Info = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host));
  let replacedValue = {};
  if (Info) {
    replacedValue = {
      '44 7360 532078': Info?.contactNumber,
    };
    if (Info.companyName === 'FEF SAS COMPANY') {
      replacedValue = {
        ...replacedValue,
        [t('refund_mobile_region_uk')]: t('refund_mobile_region_fr'),
      };
    }
    const isComa = slug.includes(',');
    return replaceString(isComa ? slug.split(',')[0] : slug.split('.')[0], replacedValue);
  }
  return slug;
};

export const getContactPageNumber = (host) => {
  const contactNumber = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host))?.contactNumber;
  if (contactNumber) return { tel: `tel: +${contactNumber}`, value: `+${contactNumber}` };
  else return { tel1: 'tel:+447360532078', tel2: '+35315745558', value1: '+44 7360 532078', value2: '+353 1 574 5558' };
};

export const getPricingContactNumber = (host, slug) => {
  let replacedValue = {};
  const contactInfo = getContactEmail(host);
  replacedValue = {
    'resumedone.co': getDomainName(host),
    'href="mailto:contact@resumedone.io"': `href="mailto:${contactInfo?.value || contactInfo}"`,
    'href="mailto:contact@resumedone.co"': `href="mailto:${contactInfo?.value || contactInfo}"`,
    Resumedone: getBrandName(host),
  };
  const Info = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host));
  if (Info) {
    const split = slug.split(',');
    const splitstr = split.slice(0, split.length - 1).join(' ');
    replacedValue = {
      ...replacedValue,
      '44 7360 532078': Info.contactNumber,
    };
    return replaceString(splitstr, replacedValue);
  } else return replaceString(slug, replacedValue);
};

export const getContactHeading = (host) => {
  const Title = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host))?.contactUsTitle;
  if (Title) return Title;
  else return 'United Kingdom / Ireland';
};

export const renderAddress = (host) => {
  const address = COMPANY_INFO.find((item) => item.domains && item.domains.includes(host))?.address;
  if (address) return address;
  else return 'Great Ponton Limited <br /> Ormond Building, 31-36 Ormond Quay Upper, Dublin 7, D07 EE37';
};

export const getPrivacyPolicyslug = (host) => {
  //temporary
  if (host === 'criarcv') {
    return 'pp_desc1_fef_new';
  }
  switch (true) {
    case TALENTREE_LIMITED_HOST.includes(host):
      return 'pp_desc_talentree';
    case KUKARIO_LIMITED_HOST.includes(host):
      return 'pp_desc_kukario';
    case DICOLARE_LIMITED_HOST.includes(host):
      return 'pp_desc_dicolare';
    case OZUARA_LIMITED_HOST.includes(host):
      return 'pp_desc_ozuara';
    case PONTON_MEDIA_HOST.includes(host):
      return 'pp_desc1_blog';
    case FEF_HOST.includes(host):
      return 'pp_desc1_fef';
    default:
      return 'pp_new_desc1';
  }
};

export const getTCSlug = (host) => {
  switch (true) {
    case KUKARIO_LIMITED_HOST.includes(host):
      return 'tc_heading_kukario';
    case DICOLARE_LIMITED_HOST.includes(host):
      return 'tc_heading_dicolare';
    case OZUARA_LIMITED_HOST.includes(host):
      return 'tc_heading_ozuara';
    case PONTON_MEDIA_HOST.includes(host):
      return 'tc_title_blog';
    case FEF_HOST.includes(host):
      return 'pp_desc_fef';
    default:
      return 'tc_title';
  }
};
export const isnewdesign = (host) => (process.env.NODE_ENV === 'test' ? false : false);

export const getIp = async (IP) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'ApiKey 08p83s7y7mkcuii4',
    },
  };
  const response = await fetch(`https://api.ipregistry.co/${IP}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }

  return response.json();
};

export const getLocation = async (IP) => {
  try {
    const data = await getIp(IP);
    const countryCode = data?.location?.country?.code;
    return countryCode;
  } catch (e) {
    return null;
  }
};

export const EXPLICITY_SET_ZAR_CURRENCY = ['cv-lite', 'resume-example'];

export const getCurrency = (domain, countryCode = '', billing_version = 1) => {
  const timeZone = moment.tz.guess(true);
  const [cont, loc] = timeZone.split('/');
  const country = getCountryName();
  const isUnitedKingdomOfGreatBritainAndNorthernIreland = ['london', 'guernsey', 'jersey', 'isle_of_man'].includes(
    loc?.toLowerCase(),
  );
  if (country === 'Switzerland') return 'CHF';
  if (countryCode === 'CO') return 'COP';
  if (countryCode && countryCode === 'ZA' && EXPLICITY_SET_ZAR_CURRENCY.includes(domain)) return 'ZAR';
  if (cont === 'Europe' && country === 'CZ') return 'CZK';

  switch (domain) {
    case 'elegantcv':
    case 'bestonlineresume':
      if (cont === 'Europe') {
        return 'EUR';
      } else return 'USD';
    case 'atelierdecv':
      if (country === 'Switzerland') {
        return 'CHF';
      } else if (country === 'Canada') {
        return 'CAD';
      } else if (cont === 'Europe') {
        return 'EUR';
      } else return 'USD';
    case 'resumedone':
      if (isUnitedKingdomOfGreatBritainAndNorthernIreland) {
        return 'GBP';
      } else if (cont === 'Europe') {
        return 'EUR';
      } else if (country === 'India') {
        return 'INR';
      }
      return 'USD';
    case 'theresumeranger':
    case 'resumizeme':
      if (cont === 'Europe') {
        return 'EUR';
      } else return 'USD';
    case 'cv-lite':
      if (cont === 'Europe') {
        return 'EUR';
      } else if (country === 'Egypt') {
        return 'EGP';
      } else if (country === 'South Africa') {
        return 'ZAR';
      } else if (country === 'Brazil') return 'BRL';
      else return 'USD';
    case 'cvtoolspro': {
      if (loc === 'Calcutta') {
        return 'INR';
      } else if (cont === 'Europe') {
        return 'EUR';
      } else if (country === 'South Africa') {
        return 'ZAR';
      } else return 'USD';
    }
    case 'resume-example': {
      if (country === 'South Africa') {
        return 'ZAR';
      } else return 'USD';
    }
    case 'cv-hebrew':
      return 'USD';
    case 'cv-pavyzdys':
    case 'modelos-de-curriculo':
      if (country === 'Brazil') return 'BRL';
      return 'USD';
    case 'cvparaugs':
    case 'zivotopis-primjer':
      return 'EUR';
    case 'creare-cv':
    case 'contoh-resume':
      return 'USD';
    case 'cv-in-arabic':
      if (loc.toLowerCase() === 'dubai') {
        return 'AED';
      } else if (loc.toLowerCase() === 'riyadh') {
        return 'SAR';
      }
      return 'USD';
    case 'thai-resume':
      return 'THB';
    case 'criarcv':
      return 'BRL';
    case 'cv-skabelon':
      return 'DKK';
    case 'cvhazirlama':
      return 'TRY';
    case 'cv-wzor':
      return 'PLN';
    case 'cvdeboss':
      switch (cont) {
        case 'Europe':
          return isUnitedKingdomOfGreatBritainAndNorthernIreland ? 'GBP' : 'EUR';
        case 'Australia':
          return 'AUD';
        default:
          return 'USD';
      }
    case 'ejemplos-curriculum':
      switch (country) {
        case 'Spain':
          return 'EUR';
        case 'Argentina':
          return 'ARS';
        default:
          return 'USD';
      }
    case 'modeles-cv':
      switch (country) {
        case 'Canada':
          return 'CAD';
        default:
          return 'EUR';
      }

    case 'cvminta':
      return 'HUF';
    case 'skriva-cv':
      return 'SEK';
    case 'cveksempel':
      return 'NOK';
    case 'cv-voorbeeld':
    case 'cv-pohja':
    case 'modellocv':
    case 'lebenslaufschreiben':
    case 'xn--mxabdxcg5aeo5c':
      return 'EUR';
    case 'cvvzor':
      return 'CZK';
    case 'xn--e1aaaggwcwefd4n':
    case 'cvforma':
    default:
      return 'USD';
  }
};

export const getCountryWiseCurrency = (country) => {
  const timeZone = moment.tz.guess(true);
  const [cont] = timeZone.split('/');
  const EuropeCountries = ['CZ', 'HU', 'NO', 'SE', 'DK', 'TR', 'PL', 'GB', 'CH'];
  if (cont === 'Europe' && !EuropeCountries.includes(country)) return 'EUR';

  switch (country) {
    case 'BR':
      return 'BRL';

    case 'MX':
      return 'MXN';

    case 'SA':
      return 'SAR';

    case 'AE':
      return 'AED';

    case 'AU':
      return 'AUD';

    case 'ZA':
      return 'ZAR';

    case 'CA':
      return 'CAD';

    case 'US':
      return 'USD';

    case 'CZ':
      return 'CZK';

    case 'HK':
      return 'HKD';

    case 'SG':
      return 'SGD';

    case 'TH':
      return 'THB';

    case 'HU':
      return 'HUF';

    case 'NO':
      return 'NOK';

    case 'SE':
      return 'SEK';

    case 'DK':
      return 'DKK';

    case 'TR':
      return 'TRY';

    case 'PL':
      return 'PLN';

    case 'AR':
      return 'ARS';

    case 'GB':
      return 'GBP';

    case 'CH':
      return 'CHF';

    case 'IN':
      return 'INR';
  }
};

export const getMollieProfileId = (host) => {
  return 'pfl_QUTBYwf3xW';
};

export const getStripePublicKey = (host) => {
  switch (host) {
    case 'resume-example':
      return isProd
        ? 'pk_live_51JMZl7Fi2os6KqAc4zKgIcxYLtUvF7ZbTIbwP7VYpyc0pI8FTWcomJATMZpshhBtciL1M9E5krMrznVqJeziLKdK00naWhErVF'
        : 'pk_test_51JMZl7Fi2os6KqAcyRQIqkHZUrOOfJw80lY4cC31tMG5xaZ9b8raXdmXemq4cqBZEmcMSQnDQ62Ee89cmWoL4nTo00zNHOACHo';
    case 'modeles-cv':
      return isProd
        ? 'pk_live_51JMuUIEjse2LGeYMc67IvBelp9knLzKik3yMjvaG0wBs2PVIrV0dWLeVqV3m7klNGwf7rHChNSclCl8BIgmWlf1600ceLH0NA1'
        : 'pk_test_51JMuUIEjse2LGeYMJOFPGFba3Mj5enrvcvjzvp2DxUwp4LJHWFGqfZaRkpxcqLPAlYOEUZ8hm5tptxW3dPi2Qyfz00dpok9itk';
    case 'cveksempel':
      return isProd
        ? 'pk_live_51KxQUiCzjFhqcBpzNrNYpL3nzkLnuZnHOwI5ShlRNcxgPMEWimu9TQODVzPFUFXPxgAae12sO3azVbu8gObYKLuA00X8eoUAl8'
        : 'pk_test_51KxQUiCzjFhqcBpzWKdUufGQ7j8UV69hxjv7LSOMAdSjzN9dZP3BLIOUA81J9wFTvlF73Fad9yTKaYmL2sfX2o4Z002lfnNX2m';
    case 'cvmenarik':
      return isProd
        ? 'pk_live_51JRxOQBZWyJReePB2rKxgn6iczKlGuLbwiZTatTvfXgymJtqs7VIYDyQy0aS6llxLAu9RpMXi9KqhIZFk1WFdHEf00S9RMW9wO'
        : 'pk_test_51JRxOQBZWyJReePBNVH9cuvXYhjP68ZFVkgRE7WjT2Fqg3ZoO86gtmsThoQvIVbw9Y7BMOALo5mfyjgwtAInTN8n00xjjPYuG8';
    case 'cv-in-arabic':
      return isProd
        ? 'pk_live_51L3e8sHttBcUDw0gSe3PrPhL6i0BnqAwmzmpgQfSXKdVxMKlOY7bEIALe1tuoIv3udifLBduisd4ILEXvw4EddFE00tZ8xTaOB'
        : 'pk_test_51L3e8sHttBcUDw0glzwvp6ETKfvjUneegPcKjBZCrSgKOHj7ebUijXt7Ia7Cpm4ck9BMk2Z33WdxluDVH2VkXajo00ITEQPC8z';
    case 'bestonlineresume':
      return isProd
        ? 'pk_live_51JQCETKYDPMxeeRhJOIuLKt1gnv2qWja2lgasgfLxLwOZcQCEQCyBAuk1byqztpIPxZ5Ykzo9UxmnfbWQpl4qTFU00G7VH9IJ8'
        : 'pk_test_51JQCETKYDPMxeeRhDTt76DK5bw44lWBTMcIFdlMi2tBAF4LKe9Qc4fkCFGyHKgswHz3Pi2VIDm3fXFwB4vvRD1QX00vkSp5wav';
    case 'cvdeboss':
      return isProd
        ? 'pk_live_51HD6OdGZqQup87EU0QUgJcSmu3SEc97GF9qufFbwuVsmPSYGZmlFPYf0gnCoSrEQXewV56SSikF3ypwZbfcDagUu00ALHJj0Pe'
        : 'pk_test_51HD6OdGZqQup87EUrOsenR4Imc6rieKhYcGz9pQPNWHpLaVoDGjLmh52ugAne2PwVYoszBrJDwJpJEajdfzdHymd00J69QbgFc';
    case 'elegantcv':
      return isProd
        ? 'pk_live_51K9SMTHhEbz1RiKCFAyBLPH1bEWdeP8VACqExLqTiDpozZt3bQneoXiTPred9BPSHBU6yMuCYitJF3JK8OM3v9g100rhkyqSc6'
        : 'pk_test_51K9SMTHhEbz1RiKC1rJNHTGJnIvoLO0EjY8UiPCwEwqOgy1a14cN67Tytrzl7K4UmXo7YckA5ksxSp7ZaNErVJMx00oMHCPth0';
    case 'cvminta':
      return isProd
        ? 'pk_live_51LpHekFTnt4a0ykrz4CkT6fghj6Cgcu7VPC2TKP7cMCX6gdl6gp4lIPTBPQrX8JLoD4JVRnox6kcRmMo90x82ub000YnxyAntI'
        : 'pk_test_51LpHekFTnt4a0ykrK3jLpSL3Bmfkpbd9nmg5La1jsQeQ9NatogFOE3xGyZk4evuFy4Vn3KswuVutEOgLNa8nOZjc00j8PZXz9y';
    case 'cv-skabelon':
      return isProd
        ? 'pk_live_51LpHEJHzAl4HYHS2LWn7heYBVcRfhJ6MezlOO7wvofVEjJEJXyOGHiMUFgloaAK05HMGmJ3wBcM4Z6cGzrkRe30I00tdxEUvDA'
        : 'pk_test_51LpHEJHzAl4HYHS26xVAxxEEp3rBG2Gli7A0gZwFxrLZLWouCrHCJpXEdLKoQISIIL4qwgMPoAFBar0rubVc1Z0f00bOWncASg';
    case 'cv-wzor':
      return isProd
        ? 'pk_live_51LpHQkGcI1Bv4ijX7nyuKkFz9ruXJncOWAo0I3lZ3OSlb6sj3ys6WtjKhNRTOHGYeWd44eAJ4Fzi2q61Iy6QD00500nFd9gMx6'
        : 'pk_test_51LpHQkGcI1Bv4ijXJzCzuCtz42miSaPMjWwncBMEXxx2ezQ1HeFf74JzmDblCgjKczya7TOVnrZa7rS2CHk7dljM00T0qGIxGM';
    case 'cv-pohja':
      return isProd
        ? 'pk_live_51LpHZuKCTNlhklmoHnOqqF1aq68WJPSgoCML6oGjTqLvvoXrmmHCRXR9Km4CV01Eautdvub3msnpAYff6hU1tqfY00U6gRNyYe'
        : 'pk_test_51LpHZuKCTNlhklmocAZJhomftOBnzNyvNlX2fvgmWkirHNS59P6glW0VxIT9IArkdZpLbwyFaGoUzp6GaOhe6VUl00jQB9ZbFm';
    case 'skriva-cv':
      return isProd
        ? 'pk_live_51LpHVWDWhbihCdP7iVGfRCl3YyYg2HVRp0xZB7xEtMCS3klmA14Hj3i9C4Z4yEkuS8uDqns1YhuN610sGeaqsKFu0064rIOe33'
        : 'pk_test_51LpHVWDWhbihCdP7rocAeheLaeQ2SDju4zWuyu4PmyjN1Qp50proEl9MDSHj4vvmVnAduKytU1JErXKllgWOrOcs00IK4HzOMg';
    case 'cvparaugs':
      return isProd
        ? 'pk_live_51M3yz3Jzre8jPYgTdBMIFxzmXv58Dv6OYsZgHUpSsGJWp4tfLtYVLqgeNbyNcchlIh7rq7fs3R0jh55ZVkQKZzib00paKGoyti'
        : 'pk_test_51M3yz3Jzre8jPYgTYztBaGD4lVK4KyZTyGjxl0QreQlbnQgT4sn10aPXKH8FGVmGsTvF3C8MhZDBRSd6ZqZ52K0800KNamlVvb';
    case 'cv-pavyzdys':
      return isProd
        ? 'pk_live_51M3ykhH7k4pGqu7vK8kUIxkNPUEnk9j154EQilbdA1kzT2ldmfYmuBz3c3Z3nKOIpf62kXsLznkxK44XsIzjKMhl00begvAXKF'
        : 'pk_test_51M3ykhH7k4pGqu7vbt6izxLX5TYxGhla9pVzkX9jaBgOQEq48ypZFpxcuzTIS4KpvKHkh1r6xXzIwR2HEvsbyIfU00QpdBpwVX';
    case 'creare-cv':
      return isProd
        ? 'pk_live_51M3yJYCZ36i0D147PYW7K314MZdy8Xj2Zt40yy0lWRsMym85djyhfAEpVMLN48XoGH4EjVHsuZWqn5U5snzqL5Wp00N1h9xO9Z'
        : 'pk_test_51M3yJYCZ36i0D1471QYP6aFsAN9iIn0Ss8IdVOQO39r9ySqZOcVbZbqh7bRq5OeHhKRTgdBpRzZE7Lo4DHuU3OV500yzzIdEP4';
    case 'lebenslaufschreiben':
      return isProd
        ? 'pk_live_51M44IQLswFt4dVWFdah2pXGHuS8fZW5Vdnyu3Z35Tuxe2eMCIGr1PngPe5YT2OivoCtvkqRIacVXiGCocT170xUL00bXvxRn2D'
        : 'pk_test_51M44IQLswFt4dVWF6TWDKtLOSIOJ0wBOvQxBySfgD8KXUwGC0LlnGecF2ZLrWWq9jyikskYesZ0q75hK6ygMEDqA0015FK4ITZ';
    case 'cvforma':
      return isProd
        ? 'pk_live_51M3ysSEUrZTyi1ONnfHTf6pi7AhKUJKL29jJHwy5HWD7cpJnGYF3QDTvQgjiHMVvprOMjWd4zSASEHUd0BWy7lOT00zAOdM2TX'
        : 'pk_test_51M3ysSEUrZTyi1ONR1K4IyuKDDfUtbq4gktWqZIMC2tZBxd9i1714bjQzdaFn6S6cSD8LFuYbsyKsJRZ8DEcvc0W00NLwqGyDJ';
    case 'contoh-resume':
      return isProd
        ? 'pk_live_51M4QLRGmM872Vk34zgoSXKqpUs4aWcjysJohMO6mSl8lWAErpnnDi4BZSf3CL7RUgUPnSHcikeO0HYqyRkTpuW8Y00Q6j1AWGz'
        : 'pk_test_51M4QLRGmM872Vk34vt6H0i6cqDXGiVoydVWW1JeQMCo9HLWJHiuTKXrtXHQh7IQ1vkU2JOK9mH8U3dbpUhN39OJY00iAQ9Mzwr';
    case 'cv-lite':
      return isProd
        ? 'pk_live_51MTQhkAUHmHMOjy4enS7NfFcR7dHMfeLm3Hm7ybnhfXtyd4dJqlPcR5URa9jwfcWF1lxSt3IdFG2pKeUvchABTNm00RLoC4DHE'
        : 'pk_test_51MTQhkAUHmHMOjy4UAqtt7tMUfAmKvWMlaEhoR8Tua6FXPhvNOfzfJkTKw9bt19WptNfWvvxk4MLzmAFAkBMxNQf00BjlOu7gb';
    case 'cvtoolspro':
      return isProd
        ? 'pk_live_51MoDV4Av8LKf4Kx91YIaqCpPFgKzZqDT1WqA3y5RLMyxjSybnEvhyHorAaiRXlYYPBM34RSu5MJV2wn6N0pvOJx700gxs24Zx2'
        : 'pk_test_51MoDV4Av8LKf4Kx9KRQ92WckPLisJVsKxrpSImOEEfELzhFnGFJqXNmftnrEscf1ODkWiTXcgIV2YfQpShq9e9L600auEYGAOo';
    case 'resumizeme':
      return isProd
        ? 'pk_live_51Mos9ZKp27jTuJB0ZCvmwcujxLk1Hl4FQk39AdyIMv7KjRy9589WU3h0qwpEhOoPETTP36AvuV1LMMOIc13vVOFY00NGrZ1YGL'
        : 'pk_test_51Mos9ZKp27jTuJB0YDjs5dZDmejBzmluwemT9eI0B0lZTunJoclvQNHdgbDCBmrskZXCb6FyXE591IzF270PwzsM00yhrwmpIp';
    case 'theresumeranger':
      return isProd
        ? 'pk_live_51Ms4WHHkzdyIlbutFkNm52CbQIBxZc0qURUfDxGp0aOUGVdqR0QuQhFcYgtvrMapNrUJIi12JgtTE24jrBObU9Qx00sfQdppyq'
        : 'pk_test_51Ms4WHHkzdyIlbutnWhM0DIWZ2fIjKsmXWjVPonzQNOpHd5RV6bvmVY1HxAswQ7qaESqiwufVJnDt9cGj5RD783q00OzJDmss1';
    case 'atelierdecv':
      return isProd
        ? 'pk_live_51PzdFOBMPvutFJ1qdAormkQafvjKDc3DsGkpKUu3BOssPhdvhAv9LnLqigdlC0m1NCAjrmVYG80ALa7l9jvUwtDt00OvvyQBmK'
        : 'pk_test_51PzdFOBMPvutFJ1qL3NKnHlJWrHT5sRBzzxWI2VW1A4k56UOJoGtpnRXXjAEPhgk5cwvARbG54BPt66QrZtoRPc100eQFirEFb';
    default:
      return isProd ? 'pk_live_KKcUCnU4P2emFXW9vhJk1Pti00Hh9Bm6op' : 'pk_test_ornAr4HZZsNYOGWoTed7LoZG00yIoQ88cQ';
  }
};

export const websiteFontLoad = (locale) => {
  switch (locale) {
    case 'el':
      return {
        websiteBold: 'Roboto Bold',
        websiteRegular: 'Roboto',
        websiteSemiBold: 'Roboto Medium',
        websiteMedium: 'Roboto Medium',
        websiteExtraBold: 'Roboto Bold',
        websiteBlack: 'Roboto Black',
        websiteBoldAlign: 'Roboto Bold',
      };
    case 'vi':
      return {
        websiteBold: 'IBM Plex Bold',
        websiteRegular: 'IBM Plex Regular',
        websiteSemiBold: 'IBM Plex SemiBold',
        websiteMedium: 'IBM Plex Medium',
        websiteExtraBold: 'IBM Plex Bold',
        websiteBlack: 'IBM Plex Regular',
      };
    default:
      return {
        websiteBold: 'Gilroy Bold',
        websiteRegular: 'Gilroy Regular',
        websiteSemiBold: 'Gilroy SemiBold',
        websiteMedium: 'Gilroy Medium',
        websiteExtraBold: 'Gilroy ExtraBold',
        websiteBlack: 'Gilroy Black',
      };
  }
};

export const getPageTitle = (domain) => {
  switch (domain) {
    case 'resumetiger':
      return 'meta_title_resumetiger';
    case 'quantumresume':
      return 'meta_title_quantumresume';
    case 'cvelevator':
      return 'meta_title_cvelevator';
    case 'cvenminutos':
      return 'meta_title_cvenminutos';
    case 'dreamresume':
      return 'meta_title_dreamresume';
    case 'melhorcv':
      return 'meta_title_melhorcv';
    case 'atelierdecv':
      return 'meta_title_atelierdecv';
    case 'cvexito':
      return 'meta_title_cvexito';
    case 'zivotopis-primjer':
      return 'meta_title_zivotopisprimjer';
    case 'theresumeranger':
      return 'meta_title_theresumeranger';
    case 'cvtoolspro':
      return 'meta_title_cvtoolspro';
    case 'cvkoostamine':
      return 'meta_title_cvkoostamine';
    case 'cv-lite':
      return 'meta_title_cvlite';
    case 'resume-nation':
      return 'meta_title_resumenation';
    case 'contoh-resume':
      return 'meta_title_contohresume';
    case 'cv-hebrew':
      return 'meta_title_cvhebrew';
    case 'cv-pavyzdys':
      return 'meta_title_cvpavyzdys';
    case 'creare-cv':
      return 'meta_title_crearecv';
    case 'modelos-de-curriculo':
      return 'meta_title_modelosdecurriculo';
    case 'cvparaugs':
      return 'meta_title_cvparaugs';
    case 'cv-in-arabic':
      return 'meta_title_cvinarabic';
    case 'thai-resume':
      return 'meta_title_thairesume';
    case 'criarcv':
      return 'meta_title_criarcv';
    case 'xn--mxabdxcg5aeo5c':
      return 'meta_title_mxabdxcg5aeo5c';
    case 'xn--e1aaaggwcwefd4n':
      return 'meta_title_e1aaaggwcwefd4n';
    case 'cvvzor':
      return 'meta_title_cvvzor';
    case 'brillantcv':
      return 'meta_title_brillantcv';
    case 'ejemplos-curriculum':
      return 'meta_title_ejemploscurriculum';
    case 'cveksempel':
      return 'meta_title_cveksempel';
    case 'cvforma':
      return 'meta_title_cvforma';
    case 'cv-voorbeeld':
      return 'meta_title_cvvoorbeeld';
    case 'cvhazirlama':
      return 'meta_title_cvhazirlama';
    case 'cv-pohja':
      return 'meta_title_cvpohja';
    case 'cv-wzor':
      return 'meta_title_cvwzor';
    case 'cv-skabelon':
      return 'meta_title_cvskabelon';
    case 'lebenslaufschreiben':
      return 'meta_title_lebenslaufschreiben';
    case 'mau-cv':
      return 'meta_title_maucv';
    case 'modeles-cv':
    case 'cvdeboss':
      return `meta_title_cvdeboss`;
    case 'cvmenarik':
      return 'meta_title_cvmenarik';
    case 'cvminta':
      return 'meta_title_cvminta';
    case 'skriva-cv':
      return 'meta_title_skrivacv';
    case 'modellocv':
      return 'meta_title_modellocv';
    case 'cv-lite':
    case 'mysmartcv':
    case 'buildmycv':
    case 'curriculumlisto':
    case 'resume-example':
    case 'bestonlineresume':
    case 'resumedone':
    default:
      return `meta_title_resumedone`;
  }
};

export const getPageDescription = (domain) => {
  switch (domain) {
    case 'resumetiger':
      return 'meta_description_resumetiger';
    case 'quantumresume':
      return 'meta_description_quantumresume';
    case 'cvelevator':
      return 'meta_description_cvelevator';
    case 'cvenminutos':
      return 'meta_description_cvenminutos';
    case 'dreamresume':
      return 'meta_description_dreamresume';
    case 'melhorcv':
      return 'meta_description_melhorcv';
    case 'atelierdecv':
      return 'meta_description_atelierdecv';
    case 'cvexito':
      return 'meta_description_cvexito';
    case 'zivotopis-primjer':
      return 'meta_description_zivotopisprimjer';
    case 'cvtoolspro':
      return 'meta_description_cvtoolspro';
    case 'cvkoostamine':
      return 'meta_description_cvkoostamine';
    case 'cv-lite':
      return 'meta_description_cvlite';
    case 'resume-nation':
      return 'meta_description_resumenation';
    case 'contoh-resume':
      return 'meta_description_contohresume';
    case 'cv-hebrew':
      return 'meta_description_cvhebrew';
    case 'cv-pavyzdys':
      return 'meta_description_cvpavyzdys';
    case 'creare-cv':
      return 'meta_description_crearecv';
    case 'modelos-de-curriculo':
      return 'meta_description_modelosdecurriculo';
    case 'cvparaugs':
      return 'meta_description_cvparaugs';
    case 'cv-in-arabic':
      return 'meta_description_cvinarabic';
    case 'thai-resume':
      return 'meta_description_thairesume';
    case 'criarcv':
      return 'meta_description_criarcv';
    case 'xn--mxabdxcg5aeo5c':
      return 'meta_description_mxabdxcg5aeo5c';
    case 'xn--e1aaaggwcwefd4n':
      return 'meta_description_e1aaaggwcwefd4n';
    case 'cvvzor':
      return 'meta_description_cvvzor';
    case 'brillantcv':
      return 'meta_description_brillantcv';
    case 'ejemplos-curriculum':
      return 'meta_description_ejemploscurriculum';
    case 'cveksempel':
      return 'meta_description_cveksempel';
    case 'cvforma':
      return 'meta_description_cvforma';
    case 'cv-voorbeeld':
      return 'meta_description_cvvoorbeeld';
    case 'cvhazirlama':
      return 'meta_description_cvhazirlama';
    case 'cv-pohja':
      return 'meta_description_cvpohja';
    case 'cv-wzor':
      return 'meta_description_cvwzor';
    case 'cv-skabelon':
      return 'meta_description_cvskabelon';
    case 'lebenslaufschreiben':
      return 'meta_description_lebenslaufschreiben';
    case 'mau-cv':
      return 'meta_description_maucv';
    case 'skriva-cv':
      return 'meta_description_skrivacv';
    case 'modellocv':
      return 'meta_description_modellocv';
    case 'modeles-cv':
    case 'cvdeboss':
      return `meta_description_cvdeboss`;
    case 'mysmartcv':
      return 'meta_description_mysmartcv';
    case 'curriculumlisto':
      return 'meta_description_curriculumlisto';
    case 'cvmenarik':
      return 'meta_description_cvmenarik';
    case 'cvminta':
      return 'meta_description_cvminta';
    case 'cv-lite':
    case 'buildmycv':
    case 'resume-example':
    case 'bestonlineresume':
    case 'resumedone':
    default:
      return 'meta_description_resumedone';
  }
};

export const getBrandName = (isSolid = false, host) => {
  switch (host) {
    case 'cv-olustur':
      return isSolid ? 'cv-olustur' : 'Cv Olustur';
    case 'applycator':
      return isSolid ? 'applycator' : 'Apply Cator';
    case 'autoapply':
      return isSolid ? 'autoapply' : 'Auto Apply';
    case 'resumetiger':
      return isSolid ? 'resumetiger' : 'Resume Tiger';
    case 'cvelevator':
      return isSolid ? 'cvelevator' : 'CV Elevator';
    case 'quantumresume':
      return isSolid ? 'Quantumresume' : 'Quantum Resume';
    case 'cvenminutos':
      return isSolid ? 'Cvenminutos' : 'CV en Minutos';
    case 'dreamresume':
      return isSolid ? 'Dreamresume' : 'Dream Resume';
    case 'melhorcv':
      return isSolid ? 'Melhorcv' : 'Melhor CV';
    case 'atelierdecv':
      return isSolid ? 'AtelierdeCV' : 'Atelier de CV';
    case 'cvexito':
      return isSolid ? 'Cvexito' : 'CV Éxito';
    case 'verycoolcv':
      return isSolid ? 'verycoolcv' : 'Very Cool Cv';
    case 'kazakhstan-cv':
      return isSolid ? 'kazakhstan-cv' : 'Kazakhstan Cv';
    case 'cvyarat':
      return isSolid ? 'Cvyarat' : 'CV Yarat';
    case 'kreatorcv':
      return isSolid ? 'Kreator Cv' : 'kreatorcv';
    case 'zivotopis-primjer':
      return isSolid ? 'Zivotopis-Primjer' : 'Zivotopis Primjer';
    case 'cvkoostamine':
      return isSolid ? 'Cvkoostamine' : 'CV koostamine';
    case 'theresumeranger':
      return isSolid ? 'TheResumeranger' : 'The Resume Ranger';
    case 'resumizeme':
      return isSolid ? 'Resumizeme' : 'ResumizeMe';
    case 'cvtoolspro':
      return isSolid ? 'CV Tools Pro' : 'CV ToolsPro';
    case 'cv-lite':
      return isSolid ? 'CV-Lite' : 'CV Lite';
    case 'contoh-resume':
      return isSolid ? 'Contoh-Resume' : 'Contoh Resume';
    case 'cv-hebrew':
      return isSolid ? 'CV-Hebrew' : 'CV Hebrew';
    case 'cv-pavyzdys':
      return isSolid ? 'CV-Pavyzdys' : 'CV Pavyzdys';
    case 'creare-cv':
      return isSolid ? 'Creare-CV' : 'Creare CV';
    case 'modelos-de-curriculo':
      return isSolid ? 'Modelos-De-Curriculo' : 'Modelos De Curriculo';
    case 'cvparaugs':
      return isSolid ? 'CVParaugs' : 'CV Paraugs';
    case 'cv-in-arabic':
      return isSolid ? 'CV-In-Arabic' : 'CV In Arabic';
    case 'thai-resume':
      return isSolid ? 'Thai-Resume' : 'Thai Resume';
    case 'criarcv':
      return isSolid ? 'CriarCV' : 'Criar CV';
    case 'xn--mxabdxcg5aeo5c':
      return 'βιογραφικο';
    case 'xn--e1aaaggwcwefd4n':
      return 'резюмепример';
    case 'brillantcv':
      return isSolid ? 'Brillantcv' : 'Brillant CV';
    case 'resume-nation':
      return 'resume-nation';
    case 'elegantcv':
      return isSolid ? 'Elegantcv' : 'Elegant CV';
    case 'modeles-cv':
      return isSolid ? 'Modeles-CV' : 'Modeles CV';
    case 'cvdeboss':
      return isSolid ? 'CVdeBoss' : 'CV De Boss';
    case 'mysmartcv':
      return isSolid ? 'MySmartCV' : 'My Smart CV';
    case 'buildmycv':
      return isSolid ? 'BuildMyCV' : 'Build MY CV';
    case 'resume-example':
      return isSolid ? 'Resume-Example' : 'Resume example';
    case 'curriculumlisto':
      return isSolid ? 'CurriculumListo' : 'Curriculum Listo';
    case 'ejemplos-curriculum':
      return isSolid ? 'Ejemplos-Curriculum' : 'Ejemplos Curriculum';
    case 'bestonlineresume':
      return isSolid ? 'BestOnlineResume' : 'Best Online Resume';
    case 'cveksempel':
      return isSolid ? 'CVEksempel' : 'CV Eksempel';
    case 'cvforma':
      return isSolid ? 'CVForma' : 'CV Forma';
    case 'cvhazirlama':
      return isSolid ? 'CVHazirlama' : 'CV Hazirlama';
    case 'cvminta':
      return isSolid ? 'CVMinta' : 'CV Minta';
    case 'cv-pohja':
      return isSolid ? 'CV-Pohja' : 'CV Pohja';
    case 'cv-skabelon':
      return isSolid ? 'CV-Skabelon' : 'CV Skabelon';
    case 'cv-voorbeeld':
      return isSolid ? 'CV-Voorbeeld' : 'CV Voorbeeld';
    case 'cv-wzor':
      return isSolid ? 'CV-Wzor' : 'CV Wzor';
    case 'cvmenarik':
      return isSolid ? 'CVMenarik' : 'CV Menarik';
    case 'cvvzor':
      return isSolid ? 'CVVzor' : 'CV Vzor';
    case 'lebenslaufschreiben':
      return isSolid ? 'LebenslaufSchreiben' : 'Lebenslauf Schreiben';
    case 'mau-cv':
      return isSolid ? 'Mau-CV' : 'Mau CV';
    case 'modellocv':
      return isSolid ? 'ModelloCV' : 'Modello CV';
    case 'skriva-cv':
      return isSolid ? 'Skriva-CV' : 'Skriva CV';
    default:
      return 'Resumedone';
  }
};

export const getDomainName = (host) => {
  switch (host) {
    case 'cv-olustur':
      return 'cv-olustur.com';
    case 'applycator':
      return 'applycator.ai';
    case 'autoapply':
      return 'autoapply.tech';
    case 'resumetiger':
      return 'resumetiger.app';
    case 'cvelevator':
      return 'cvelevator.app';
    case 'quantumresume':
      return 'quantumresume.app';
    case 'cvenminutos':
      return 'cvenminutos.com';
    case 'dreamresume':
      return 'dreamresume.app';
    case 'melhorcv':
      return 'melhorcv.com';
    case 'atelierdecv':
      return 'atelierdecv.com';
    case 'cvexito':
      return 'cvexito.com';
    case 'verycoolcv':
      return 'verycoolcv.com';
    case 'kazakhstan-cv':
      return 'kazakhstan-cv.com';
    case 'cvyarat':
      return 'cvyarat.app';
    case 'kreatorcv':
      return 'kreatorcv.com';
    case 'zivotopis-primjer':
      return 'zivotopis-primjer.com';
    case 'cvkoostamine':
      return 'cvkoostamine.com';
    case 'theresumeranger':
      return 'theresumeranger.com';
    case 'resumizeme':
      return 'resumizeme.com';
    case 'cvtoolspro':
      return 'cvtoolspro.com';
    case 'cv-lite':
      return 'cv-lite.com';
    case 'contoh-resume':
      return 'contoh-resume.com';
    case 'cv-hebrew':
      return 'cv-hebrew.com';
    case 'cv-pavyzdys':
      return 'cv-pavyzdys.com';
    case 'creare-cv':
      return 'creare-cv.com';
    case 'modelos-de-curriculo':
      return 'modelos-de-curriculo.com';
    case 'cvparaugs':
      return 'cvparaugs.com';
    case 'cv-in-arabic':
      return 'cv-in-arabic.com';
    case 'thai-resume':
      return 'thai-resume.com';
    case 'criarcv':
      return 'criarcv.com';
    case 'brillantcv':
      return 'brillantcv.com';
    case 'resume-nation':
      return 'resume-nation.com';
    case 'elegantcv':
      return 'elegantcv.app';
    case 'modeles-cv':
      return 'modeles-cv.fr';
    case 'cvdeboss':
      return 'cvdeboss.com';
    case 'mysmartcv':
      return 'mysmartcv.co';
    case 'buildmycv':
      return 'buildmycv.io';
    case 'curriculumlisto':
      return 'curriculumlisto.com';
    case 'resume-example':
      return 'resume-example.com';
    case 'bestonlineresume':
      return 'bestonlineresume.com';
    case 'ejemplos-curriculum':
      return 'ejemplos-curriculum.com';
    case 'cveksempel':
      return 'cveksempel.com';
    case 'cvforma':
      return 'cvforma.com';
    case 'cvhazirlama':
      return 'cvhazirlama.cool';
    case 'cvminta':
      return 'cvminta.com';
    case 'cv-pohja':
      return 'cv-pohja.com';
    case 'cv-skabelon':
      return 'cv-skabelon.com';
    case 'cv-voorbeeld':
      return 'cv-voorbeeld.com';
    case 'cv-wzor':
      return 'cv-wzor.com';
    case 'cvmenarik':
      return 'cvmenarik.com';
    case 'cvvzor':
      return 'cvvzor.com';
    case 'lebenslaufschreiben':
      return 'lebenslaufschreiben.com';
    case 'mau-cv':
      return 'mau-cv.com';
    case 'modellocv':
      return 'modellocv.com';
    case 'skriva-cv':
      return 'skriva-cv.com';
    case 'xn--mxabdxcg5aeo5c':
      return 'βιογραφικο.com';
    case 'xn--e1aaaggwcwefd4n':
      return 'резюмепример.com';
    default:
      return 'resumedone.co';
  }
};

export const getContactEmail = (host) => {
  switch (host) {
    case 'xn--mxabdxcg5aeo5c':
      return { value: 'contact@xn--mxabdxcg5aeo5c.com', title: 'contact@βιογραφικο.com' };
    case 'xn--e1aaaggwcwefd4n':
      return { value: 'contact@xn--e1aaaggwcwefd4n.com', title: 'contact@резюмепример.com' };
    case 'cvdeboss':
      return 'contact@cvdeboss.com';
    case 'resumedone':
      return 'contact@resumedone.io';
    default:
      return `support@${getDomainName(host)}`;
  }
};

export const getAvailableLanguages = (host) => {
  switch (host) {
    case 'mysmartcv':
      return AVAILABLE_PLATFORM_LANGUAGES;
    case 'cvdeboss':
    case 'curriculumlisto':
      return [];
    case 'buildmycv':
    case 'bestonlineresume':
    case 'resume-nation':
    case 'elegantcv':
    case 'cvtoolspro':
    case 'theresumeranger':
    case 'verycoolcv':
      return [...AVAILABLE_PLATFORM_LANGUAGES, LANGUAGES_CODES.FRENCH];
    case 'cv-lite':
    case 'resumizeme':
    case 'resumedone':
    default:
      return AVAILABLE_PLATFORM_LANGUAGES;
  }
};

export const getRecordsByBase = async (baseName, filterByFormula) => {
  const base = new Airtable({ apiKey: env.AIRTABLE_TOKEN }).base('appj9JKWw40GaFGxC');
  let allRecords = [];
  return new Promise((resolve, reject) => {
    base(baseName)
      .select({
        view: 'Grid view',
        // Specify the filter conditions
        filterByFormula: filterByFormula ? filterByFormula : '',
      })
      .eachPage(
        // Batch size of 100
        (records, fetchNextPage) => {
          const mappedRecords = records.map((record) => record.fields);
          allRecords = [...allRecords, ...mappedRecords];

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        (err) => {
          // Called when there are no records left
          return err ? reject(err) : resolve(allRecords);
        },
      );
  });
};

export const getRule = (allRules, domain) => {
  const countryName = 'Country';
  const defaultDomain = 'Domain';
  const hideModal = 'Hide Modal';

  const { rules = [], defaultRules = [] } = allRules || {};
  const countryCode = getCountry();
  const exception = rules.find((r) => r[countryName] === countryCode && r[defaultDomain] === domain);
  const domainDefaultRules = defaultRules.find((r) => r[defaultDomain] === domain);
  if (exception) return { ...exception, [hideModal]: domainDefaultRules[hideModal] };
  return domainDefaultRules;
};

export const selectUrlBasedOnPercentage = (urlsWithPercentages) => {
  if (!urlsWithPercentages) return;
  let totalPercentage = urlsWithPercentages.reduce((acc, item) => acc + item.Percentage, 0);
  if (totalPercentage !== 1) {
    return 'https://www.trustpilot.com/review/cv-lite.com';
  }
  let random = Math.random();
  let cumulative = 0;
  for (let i = 0; i < urlsWithPercentages.length; i++) {
    cumulative += urlsWithPercentages[i].Percentage;
    if (random <= cumulative) {
      return urlsWithPercentages[i].URL;
    }
  }
  return urlsWithPercentages[urlsWithPercentages.length - 1].URL;
};

export const getRandomEmoji = () => {
  const emojis = [
    '👨‍✈️',
    '🔬',
    '🔭',
    '🗃️',
    '💸',
    '👩‍✈️',
    '🧰',
    '🖋️',
    '🧑‍🚀',
    '👩‍🚀',
    '👨‍🚀',
    '🧑‍🎨',
    '👨‍🎨',
    '👩‍🎨',
    '🧑‍🚒',
    '👨‍🚒',
    '👩‍🚒',
    '💻',
    '🖥️',
    '🚨',
    '🚓',
    '🚒',
    '🚑',
    '🚕',
    '📈',
    '📉',
    '📊',
    '🧑‍🎓',
    '👨‍🎓',
    '👩‍🎓',
    '🧑‍🏫',
    '👩‍🏫',
    '👨‍🏫',
    '👨‍⚖️',
    '👩‍⚖️',
    '👨‍⚖️',
    '🧑‍🍳',
    '👩‍🍳',
    '🧑‍🔧',
    '👨‍🔧',
    '👩‍🔧',
    '🧑‍🏭',
    '👨‍🏭',
    '👩‍🏭',
    '🧑‍💼',
    '👨‍💼',
    '👩‍💼',
    '🧑‍🔬',
    '👩‍🔬',
    '👨‍🔬',
    '🧑‍💻',
    '👨‍💻',
    '👩‍💻',
    '🧑‍✈️',
    '👮‍♀️',
    '🕵️',
    '🕵️‍♂️',
    '🕵️‍♀️',
    '👷‍♂️',
    '👷‍♀️',
    '🗒️',
    '👔',
    '🦺',
    '📑',
    '🗂️',
    '📒',
    '🎓',
    '📜',
    '💼',
    '📚',
    '🔍',
    '📌',
    '📍',
    '📎',
  ];

  return emojis[~~(Math.random() * emojis.length)];
};

export const convertTextToHTML = (rawText) => {
  const textWithBold = rawText.replace(/(\b[A-Z][A-Z]+\b)/g, '<b>$1</b>');

  const textWithItalic = textWithBold.replace(/"([^"]+)"/g, '<i>$1</i>');

  return `<p>${textWithItalic}</p>`;
};

const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

const prettifyHTML = (html) => {
  const options = {
    indent_size: 2,
    wrap_attributes: 'auto',
    wrap_attributes_indent_size: 2,
    end_with_newline: true,
  };

  return beautify.html(html, options);
};

const convertTextToHTMLV2 = (rawText) => {
  const textWithLineBreaks = rawText?.replace(/\n/g, '<br/>');
  const textWithBold = textWithLineBreaks?.replace(/\*([^\*]+)\*/g, '<b>$1</b>');
  return `${textWithBold}`;
};

export const processText = (inputText) => {
  if (isHTML(inputText)) {
    return prettifyHTML(inputText);
  }
  const hasHTMLEntity = (text) => /(&[#A-Za-z0-9]+;)|(<\/?[a-z][\s\S]*>)/i.test(text);
  while (true) {
    const parsed = parse(inputText);
    if (typeof parsed === 'string' && hasHTMLEntity(parsed)) {
      inputText = parsed;
    } else {
      break;
    }
  }
  return convertTextToHTMLV2(inputText);
};

export const ensureHttpPrefix = (url) => {
  if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
    return 'http://' + url;
  }
  return url;
};

export const jobTypeTag = (jobType) => {
  const fullTimeRegex = /full.*time/i;
  const partTimeRegex = /part.*time/i;
  const contractRegex = /contract/i;
  const temporaryRegex = /temporary/i;
  const internshipRegex = /intern/i;

  switch (true) {
    case fullTimeRegex.test(jobType):
      return 'Full-time';
    case partTimeRegex.test(jobType):
      return 'Part-time';
    case contractRegex.test(jobType):
      return 'Contractual';
    case temporaryRegex.test(jobType):
      return 'Temporary';
    case internshipRegex.test(jobType):
      return 'Internship';
    default:
      return jobType;
  }
};

export const filteredRenderedJobType = (jobTypeArray) => {
  //to remove duplicates type , example: 'contract','Contract','Contractual','Contractor',...
  const filteredData = [...new Set(jobTypeArray?.map((type) => jobTypeTag(type)))];
  return filteredData;
};

export const renderCTAText = (company, t) => {
  switch (company?.position[0]) {
    case 0:
      return t('jobtrack_start_application_text');
    case 1:
      return t('jobtrack_already_sent_application_text');
    case 2:
      return t('jobtrack_applied_cta');
    case 3:
      return t('jobtrack_interview_cta');
    case 4:
      return t('jobtrack_offer_cta');
    case 5:
      return t('jobtrack_rejectedoffer_cta');
    default:
      return t('jobtrack_already_sent_application_text');
  }
};

export const renderColumnName = (columnName, t) => {
  switch (columnName) {
    case 'Wishlist':
      return t('jobtrack_wishlist_column');
    case 'Applying':
      return t('jobtrack_applying_column');
    case 'Applied':
      return t('jobtrack_applied_column');
    case 'Interview':
      return t('jobtrack_interview_column');
    case 'Offer':
      return t('jobtrack_offer_column');
    case 'Offer Accepted':
      return t('jobtrack_offer_accpeted_column');
    case 'Offer Rejected':
      return t('jobtrack_offer_rejected_column');
  }
};

export const compareStrings = (str1, str2) => {
  const formattedStr1 = str1.replace(/-/g, '').toLowerCase();
  const formattedStr2 = str2.replace(/-/g, '').toLowerCase();
  return formattedStr1 === formattedStr2;
};

export const getPortalName = (portal) => {
  switch (portal.toLowerCase()) {
    case 'google':
      return 'GG';
    case 'adzuna':
      return 'AD';
    case 'jobadx':
      return 'JA';
    case 'jobg8':
      return 'G8';
  }
};

export const removeDuplicateUrls = (arr) => {
  if (arr.length < 0) return;
  const uniqueUrls = {};
  return arr.filter((item) => {
    if (!uniqueUrls[item.url]) {
      uniqueUrls[item.url] = true;
      return true;
    }
    return false;
  });
};

export const isExistingEmail = (error) => {
  switch (error) {
    case 'email already exists':
      return true;
    default:
      return false;
  }
};

export const isGmail = (email) => {
  const gmailRegex = /@gmail\.com$/i;
  return gmailRegex.test(email);
};

export const OUTBRAIN_HOST = [
  'bestonlineresume',
  'ejemplos-curriculum',
  'cvtoolspro',
  'modeles-cv',
  'cvdeboss',
  'cv-in-arabic',
  'cvvzor',
  'creare-cv',
  'cv-pavyzdys',
];

export const AI_MODAL_TEXT_CONTROL = [
  {
    text: 'ai_modal_list_1',
    bulletColor: 'var(--additional-colors-pink-old)',
    subText: 'ai_modal_desc_1_v1',
  },
  {
    text: 'ai_modal_list_2',
    bulletColor: 'var(--additional-colors-purple)',
    subText: 'ai_modal_desc_2_v1',
  },
  {
    text: 'ai_modal_list_3',
    bulletColor: 'var(--additional-colors-yellow)',
    subText: 'ai_modal_desc_3_v1',
  },
];

export const isDraftContentEmpty = (descriptionText) => {
  if (!descriptionText) return true;
  try {
    const draftContent = JSON.parse(descriptionText);
    return !draftContent?.blocks.some((block) => !!block.text);
  } catch (e) {
    return false;
  }
};

export const countAlphanumericCharacters = (str) => {
  const regex = /\p{L}|\p{N}/gu;
  const matches = str.match(regex);
  return matches ? matches.length : 0;
};
export const isDraftContentOver30 = (descriptionText) => {
  if (!descriptionText) return false;
  try {
    const draftContent = JSON.parse(descriptionText);
    const characterCount = draftContent?.blocks.reduce((acc, current) => {
      return acc + countAlphanumericCharacters(current.text);
    }, 0);
    return characterCount >= 30;
  } catch (e) {
    //Error occured because of json parse
    if (e instanceof SyntaxError && typeof descriptionText === 'string') {
      return countAlphanumericCharacters(descriptionText) >= 30;
    }
    return false;
  }
};
export const retrieveDraftContent = (descriptionText) => {
  try {
    const draftContent = JSON.parse(descriptionText);
    return draftContent?.blocks.map((block) => block.text).join(' ');
  } catch (e) {
    return descriptionText;
  }
};

export const getLanguageNameByCode = (isoCodeLanguage) => {
  return Object.keys(LANGUAGES_CODES).filter((language) => LANGUAGES_CODES[language] === isoCodeLanguage)[0];
};

const getExperienceCount = (items) => {
  if (!items) {
    return null;
  }
  const minStartDateYear = items.reduce((minYear, obj) => {
    if (!obj?.fields?.startDate) {
      return minYear;
    }
    const startDate = new Date(obj?.fields?.startDate);
    const year = startDate.getFullYear();
    if (minYear === null || year < minYear) {
      return year;
    } else {
      return minYear;
    }
  }, null);
  const currentDate = new Date();
  return !!minStartDateYear ? currentDate.getFullYear() - minStartDateYear : null;
};
const getPromptParts = (source, t, locale) => {
  const blocks = source.blocks;
  const employementItems = blocks.find((block) => block?.type === 'EMPLOYMENT')?.items;
  const skillsItems = blocks.find((block) => block?.type === 'SKILLS')?.items;
  const educationItems = blocks.find((block) => block?.type === 'EDUCATION')?.items;
  const skills = skillsItems && skillsItems.map((skill) => skill?.fields?.skill).join(', ');
  let lastEmployment = null,
    lastEducation = null;
  if (employementItems) {
    for (let i = 0; i < employementItems.length; i++) {
      if (employementItems[i]?.fields?.title) {
        lastEmployment = employementItems[i];
        break;
      }
    }
  }
  if (educationItems) {
    for (let j = 0; j < educationItems.length; j++) {
      if (educationItems[j]?.fields?.degree) {
        lastEducation = educationItems[j];
        break;
      }
    }
  }

  const experienceCount = getExperienceCount(employementItems);
  const introPrompt = t('prompt_summary_screen_v2_intro', locale);
  const closingPrompt = t('prompt_summary_screen_v2_closing', locale);
  const employmentName = replaceString(t('prompt_summary_screen_v2_experience_p1', locale), {
    LAST_JOB_TITLE: lastEmployment?.fields?.title,
  });
  const employer = lastEmployment?.fields?.employer
    ? replaceString(t('prompt_summary_screen_v2_experience_p2', locale), {
        LAST_EMPLOYER_NAME: lastEmployment?.fields?.employer,
      })
    : '';
  const experienceCountP =
    experienceCount && experienceCount > 0
      ? replaceString(t('prompt_summary_screen_v2_experience_p3', locale), { EXPERIENCE_COUNT: experienceCount })
      : '';
  const employmentPrompt = `${employmentName}${employer}${experienceCountP}`;
  const educationPrompt = replaceString(
    t(
      lastEducation?.fields?.endDate
        ? 'prompt_summary_screen_v2_education_with_date'
        : 'prompt_summary_screen_v2_education_without_date',
      locale,
    ),
    {
      LAST_EDUCATION_DEGREE_DATE: new Date(lastEducation?.fields?.endDate).getFullYear(),
      LAST_EDUCATION_DEGREE: lastEducation?.fields?.degree,
    },
  );
  const skillsPrompt = replaceString(t('prompt_summary_screen_v2_skill', locale), { SKILLS_LIST: skills });
  return {
    lastEmployment,
    lastEducation,
    skills,
    introPrompt,
    closingPrompt,
    employmentPrompt,
    educationPrompt,
    skillsPrompt,
  };
};

export const buildAIRephrasingPrompt = (item, type, t, locale, isNewRephrasingPrompt = false) => {
  const bullet = 'use • as a bullet character';
  let withoutBullet = false;
  let prompt = '';
  if (type === 'EMPLOYMENT') {
    if (isNewRephrasingPrompt) {
      return replaceString(t('prompt_rephrasing_experience_new', locale), {
        '{{EXPERIENCE_TITLE}}': item?.fields?.title,
        '{{EXPERIENCE_CONTENT}}': retrieveDraftContent(item?.fields?.description),
        '{{RESUME_LANGUAGE}}': getLanguageNameByCode(locale),
      });
    } else {
      prompt = replaceString(t('prompt_rephrasing_experience', locale), { JOB_TITLE: item?.fields?.title });
    }
  } else if (type === 'EDUCATION') {
    prompt = replaceString(t('prompt_rephrasing_education', locale), { EDUCATION_DEGREE: item?.fields?.degree });
  } else if (type === 'PROFESSIONAL_SUMMARY') {
    withoutBullet = true;
    prompt = t('prompt_rephrasing_summary', locale);
  }
  const languagePrompt = replaceString(t('prompt_language_setting', locale), {
    RESUME_LANGUAGE: getLanguageNameByCode(locale),
  });
  return `${prompt}${withoutBullet ? '' : bullet}: "${retrieveDraftContent(item?.fields?.description)}".${languagePrompt}`;
};

export const buildAISuggestionPrompt = (
  source,
  item,
  type,
  t,
  locale,
  info,
  isNewCTAsVar = false,
  isNewPrompt = false,
) => {
  const singleDataStructurationPrompt =
    'Please structure your output as a JavaScript array, commencing with an opening bracket, such as: ["response"],make sure to use double quotation and not single quotation inside the array. Within this array, place the singular response.It is important to avoid any additional text preceding the desired response.';
  const bulletDataStructurationPrompt =
    'Please structure your output as a JavaScript array, commencing with an opening bracket, such as: ["First bullet point ","Second bullet point",..."Nth bullet point"],make sure to use double quotation and not single quotation inside the array.It is important to avoid any additional text preceding the desired response.';
  let isSingleData = false;
  let prompt = '';
  const promptV2 = locale === 'fr' || locale === 'es';
  const isNewPromptExp = isNewCTAsVar && info;
  if (type === 'EMPLOYMENT') {
    if (!isDraftContentEmpty(item?.fields?.description)) {
      prompt = replaceString(
        t(
          isNewPromptExp
            ? info?.prompt
            : isNewPrompt
              ? 'prompt_experience_screen_v4'
              : promptV2
                ? 'prompt_experience_screen_v1_v2'
                : 'prompt_experience_screen_v1',
          locale,
        ),
        {
          JOB_DESCRIPTION: retrieveDraftContent(item?.fields?.description),
          ...((promptV2 || isNewPrompt || isNewPromptExp) && { JOB_TITLE: item?.fields?.title }),
        },
      );
    } else if (item?.fields?.title) {
      prompt = replaceString(
        t(
          isNewPromptExp ? info?.prompt : isNewPrompt ? 'prompt_experience_screen_v4' : 'prompt_experience_screen_v2',
          locale,
        ),
        {
          JOB_TITLE: item?.fields?.title,
        },
      );
    }
  }
  if (type === 'EDUCATION') {
    if (!isDraftContentEmpty(item?.fields?.description)) {
      prompt = replaceString(t(promptV2 ? 'prompt_education_screen_v2_v2' : 'prompt_education_screen_v1', locale), {
        EDUCATION_DESCRIPTION: retrieveDraftContent(item?.fields?.description),
        EDUCATION_DEGREE: item?.fields?.degree,
      });
    } else if (item?.fields?.degree) {
      prompt = replaceString(t('prompt_education_screen_v2', locale), {
        EDUCATION_DEGREE: item?.fields?.degree,
      });
    }
  }
  if (type === 'PROFESSIONAL_SUMMARY') {
    isSingleData = true;
    const {
      lastEmployment,
      lastEducation,
      skills,
      introPrompt,
      closingPrompt,
      employmentPrompt,
      educationPrompt,
      skillsPrompt,
    } = getPromptParts(source, t, locale);
    if (!isDraftContentEmpty(item?.fields?.description)) {
      prompt = replaceString(t('prompt_summary_screen_v1', locale), {
        SUMMARY_DESCRIPTION: retrieveDraftContent(item?.fields?.description),
      });
    } else if (!!lastEmployment && !!lastEducation && !!skills) {
      prompt = `${introPrompt} ${employmentPrompt} ${skillsPrompt} ${educationPrompt} ${closingPrompt}`;
    } else if (!!lastEmployment && !!lastEducation && !skills) {
      prompt = `${introPrompt} ${employmentPrompt} ${educationPrompt} ${closingPrompt}`;
    } else if (!!lastEmployment) {
      prompt = `${introPrompt} ${employmentPrompt} ${closingPrompt}`;
    }
  }
  return `${prompt} ${isSingleData ? singleDataStructurationPrompt : bulletDataStructurationPrompt}`;
};

export const buildAISuggestionPromptLoadMore = (item, type, t, locale) => {
  const bulletDataStructurationPrompt =
    'Please structure your output as a JavaScript array, commencing with an opening bracket, such as: ["First bullet point ","Second bullet point",..."Nth bullet point"],make sure to use double quotation and not single quotation inside the array.It is important to avoid any additional text preceding the desired response.';
  let prompt = '';
  if (type === 'EMPLOYMENT') {
    prompt = replaceString(t('prompt_load_more_experience', locale), { JOB_TITLE: item?.fields?.title });
  }
  if (type === 'EDUCATION') {
    prompt = replaceString(t('prompt_load_more_education', locale), { EDUCATION_DEGREE: item?.fields?.degree });
  }
  return `${prompt} ${bulletDataStructurationPrompt}`;
};

export const showCookie = () => {
  const country = getCountry();
  return !!REGION_MAP.europe.find((k) => k.code === country);
};

export const writeStoreRedirectCookie = (host) => {
  const now = new Date();
  const time = now.getTime();
  // + 21 days
  const expireTime = time + 24 * 60 * 60 * 21 * 1000;
  now.setTime(expireTime);
  setCookie(null, '__gp_storeRedirectionDomain', host, { expires: now });
};

export const getStoreRedirectCookie = () => {
  const cookies = parseCookies();
  const { __gp_storeRedirectionDomain: storeRedirect } = cookies;
  return storeRedirect;
};

export const destroyStoreRedirectCookie = () => {
  destroyCookie(null, '__gp_storeRedirectionDomain');
};

export const HOTJAR_HOSTS = ['cvdeboss', 'cvtoolspro', 'cv-lite'];

const removeStyleFromString = (text) => {
  return text.replaceAll('*', '');
};

export const parseDraftText = (draftjsString, parse) => {
  if (!draftjsString) return null;
  let content;
  try {
    content = JSON.parse(draftjsString);
  } catch (error) {
    return draftjsString;
  }
  let text = '';
  if (content.hasOwnProperty('blocks')) {
    const blocks = content.blocks;
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      if (block.hasOwnProperty('text')) {
        if (block.text != '') {
          text += block.text;
          if (parse && i !== blocks.length - 1) {
            text += '<br/>';
          }
        }
      }
    }
  }
  return removeStyleFromString(text);
};
export const firstCharToUpperCase = (text) => {
  if (!text) {
    return '';
  } else {
    return text[0].toUpperCase() + text.slice(1);
  }
};

export const isFontUpdate = (language) => {
  if (language === 'vi') {
    return true;
  }
  return false;
};

const countriesToDisableApplePay = ['france', 'belgium', 'croatia'];

export const shouldEnableApplePay = () => {
  const country = getCountryName();
  return !countriesToDisableApplePay.includes(country.toLowerCase());
};

export const getActiveRepositingVar = () => {
  const country = getCountry();
  if (BASIC_POSITION_COUNTRY.includes(country)) return 'with_basic_repositioning';
  else if (COMPLETE_POSITION_COUNTRY.includes(country)) return 'with_complete_repositioning';
  else marvelEmitter.getActiveVariant('exp_block_repositing');
};

export const getPreviewBlocksHeight = (customBlock = [], customSkillsBlock = []) => {
  const getHeight = (id) => {
    const e = document?.getElementById(id);
    if (!!e) return e.offsetHeight;
    else return 0;
  };
  const getCustomHeight = (blocks) => {
    if (!blocks.length) {
      return [];
    } else {
      let customBlockHeight = [];
      blocks.map((id) => {
        const height = getHeight(`${id}-block`);
        customBlockHeight.push({ blockId: id, height: height });
      });
      return customBlockHeight;
    }
  };
  return {
    EDUCATION: getHeight('EDUCATION-block'),
    EMPLOYMENT: getHeight('EMPLOYMENT-block'),
    SKILLS: getHeight('SKILLS-block'),
    PROFESSIONAL_SUMMARY: getHeight('PROFESSIONAL_SUMMARY-block'),
    SOCIAL_LINKS: getHeight('SOCIAL_LINKS-block'),
    LANGUAGES: getHeight('LANGUAGES-block'),
    COURSES: getHeight('COURSES-block'),
    USER_DETAILS: getHeight('USER-block'),
    HOBBIES: getHeight('HOBBIES-block'),
    CUSTOM: getCustomHeight(customBlock),
    EXTRA_CURRICULAR: getHeight('EXTRA_CURRICULAR-block'),
    INTERNSHIPS: getHeight('INTERNSHIPS-block'),
    REFERENCES: getHeight('REFERENCES-block'),
    DRIVING_LICENSE: getHeight('DRIVING_LICENSE-block'),
    PUBLICATIONS: getHeight('PUBLICATIONS-block'),
    PERSONAL_DETAILS: getHeight('PERSONAL_DETAILS-block'),
    SOFT_SKILLS: getHeight('SOFT_SKILLS-block'),
    HARD_SKILLS: getHeight('HARD_SKILLS-block'),
    USER_DETAILS_LEFT: getHeight('USER-block-left'),
    CONTACT_DETAIL: getHeight('CONTACT-DETAIL'),
    CUSTOM_SKILLS_CATEGORY: getCustomHeight(customSkillsBlock),
  };
};

export const getInitalBlockTitle = (type, name, t) => {
  switch (type) {
    case 'SOFT_SKILLS':
      return name === 'Skills' ? t('skills') : name;
    case 'HARD_SKILLS':
      return name === 'Hard Skills' ? t('hard_skills') : name;
    case 'CUSTOM_SKILLS_CATEGORY':
      return name.toLowerCase() === 'untitled' ? t('untitled') : name;
  }
};

export const DISABLE_UPLOAD_RESUME_EXP = [
  'en',
  'fr',
  'nl',
  'it',
  'hr',
  'ar',
  'vi',
  'lv',
  'pt-br',
  'ro',
  'sr',
  'es',
  'ru',
  'tr',
  'cs',
  'id',
  'de',
  'hu',
  'ko',
  'sl',
  'th',
  'zh-cn',
  'ms',
  'no',
  'pt',
  'uz',
  'tj',
  'bg',
  'ca',
  'bn',
  'kk',
  'ja',
  'hi',
  'ka',
  'pl',
  'et',
  'az',
  'da',
  'fi',
  'el',
  'he',
  'lt',
  'sv',
];

export const DISABLE_UPLOAD_RESUME_EXP_MOBILE = [
  'et',
  'en',
  'sl',
  'tr',
  'ko',
  'vi',
  'az',
  'sv',
  'ro',
  'ru',
  'id',
  'hu',
  'no',
];

export const DISABLE_UPLOAD_RESUME_EXP_CONTROL_ON_DESK = ['tr'];

export const without_upload = [
  'kk',
  'bn',
  'ca',
  'bg',
  'tj',
  'uz',
  'ms',
  'zh-cn',
  'th',
  'ar',
  'vi',
  'ru',
  'id',
  'hr',
  'sl',
  'ja',
  'hi',
  'ka',
  'pl',
  'et',
];

export const without_upload_mobile = ['ru', 'ko', 'az', 'ro', 'id', 'sl', 'et'];

export const HIDE_UPLOAD_RESUME = ['sr'];

export const getDateOfBirth = (data) => {
  if (Array.isArray(data) && data.length > 0) {
    const dateOfBirth = data[0].fields.dateOfBirth;
    return dateOfBirth;
  } else {
    return false;
  }
};

export const isEmptyOrUndefinedOrNull = (str) => {
  return !str || (str && str?.trim() === '');
};
export const savedDataOnIndex = (key, value) => {
  const request = indexedDB.open('resumedoneDB', 1);

  request.onupgradeneeded = function (event) {
    let db = event.target.result;
    if (!db.objectStoreNames.contains('Settings')) {
      db.createObjectStore('Settings', { keyPath: 'key' });
    }
  };

  request.onsuccess = function (event) {
    let db = event.target.result;
    console.log('Database opened successfully');
    updateData(db, key, value);
  };

  request.onerror = function (event) {
    console.error('Database error:', event.target.error);
  };
};

function updateData(db, key, value) {
  const transaction = db.transaction(['Settings'], 'readwrite');
  const store = transaction.objectStore('Settings');
  const item = {
    key: key,
    value: value,
  };

  const req = store.put(item);
  req.onsuccess = function () {
    console.log('Data saved successfully');
  };
  req.onerror = function (event) {
    console.error('Error saving data:', event.target.error);
  };
}

export const expTagsTemplates = ['budapest', 'riga', 'rotterdam', 'perth', 'chicago', 'prague', 'brunei'];

export function mkResumeToStr(resumeObj, accents = false) {
  let resumeStr = '';
  mkStr(resumeObj);
  if (!accents) return resumeStr;
  return removeAccents(resumeStr, true, true);

  function mkStr(obj, currentKey = '') {
    if (typeof obj === 'object') {
      for (const key in obj) {
        if (
          Object.hasOwnProperty.call(obj, key) &&
          ![
            'resumeId',
            'startDate',
            'endDate',
            'author',
            'id',
            'animationKey',
            'importance',
            'order',
            'type',
            '__typename',
            currentKey != 'details' ? 'title' : '',
            'position',
            'settings',
            'userId',
            'uploadedResumeS3Url',
            'steps',
            'currentStep',
            'createdAt',
            'aws:rep:deleting',
            'aws:rep:updateregion',
            'aws:rep:updatetime',
            'updatedAt',
            'skillLevel',
            'languageLevel',
            currentKey == '' && 'name',
          ].includes(key)
        ) {
          const element = obj[key];
          mkStr(element, key);
        }
      }
    } else if (typeof obj === 'string' && currentKey === 'description' && /blocks/.test(obj)) {
      try {
        let parsedText = '';
        const parsed = JSON.parse(obj);
        const blocks = parsed.blocks;
        blocks.forEach((el) => {
          parsedText += `${el.text} `;
        });
        resumeStr += parsedText;
      } catch (error) {
        resumeStr += `${obj} `;
      }
    } else if (typeof obj === 'string') {
      resumeStr += `${obj} `;
    }
  }
}

function zeros(x, y) {
  let array = new Array(x);

  // Fill each row with an array of y zeros
  for (let i = 0; i < x; i++) {
    array[i] = new Array(y).fill(0);
  }

  return array;
}

function levenshteinDistance(s1, s2) {
  //Calculates the Levenshtein distance between two strings.
  const m = s1.length;
  const n = s2.length;
  const dp = zeros(m + 1, n + 1);

  for (let i = 0; i <= m; i++) {
    dp[i][0] = i;
  }

  for (let j = 0; j <= n; j++) {
    dp[0][j] = j;
  }

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      const cost = s1[i - 1] == s2[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(dp[i - 1][j] + 1, dp[i][j - 1] + 1, dp[i - 1][j - 1] + cost);
    }
  }

  return dp[m][n];
}

export function checkSimilarity(text1, text2, threshold = 0.5) {
  //Checks the similarity between two texts based on Levenshtein distance.
  //Returns True if the texts are considered similare, False otherwise.
  //The threshold parameter determines the maximum allowed similarity ratio.
  try {
    if (!text1 || text1?.trim() == '' || !text2 || text2?.trim() == '') return false;
    const distance = levenshteinDistance(text1, text2);
    const length = Math.max(text1.length, text2.length);
    const similarity_ratio = 1 - distance / length;
    return similarity_ratio >= threshold;
  } catch (e) {
    console.error(e?.message);
    return false;
  }
}

export function getOccurences(keywords = [], text = '') {
  // Get the occurence number of each keyword in the provided text
  // return {text<Sring>, occurence<Integer>}[]
  if (!text || !keywords || !keywords.length) {
    return [];
  }
  const _normalizedText = removeAccents(text);
  const _textWordBag = _normalizedText.split(' ');
  const result = keywords.map((kw) => ({ text: kw, occurence: checkOccurence(kw, _textWordBag) }));

  return result;

  function checkOccurence(kw, textWordBag) {
    // Get the occurence number of a keyword using text's word bag (array of words)

    if (!(kw && textWordBag)) {
      return 0;
    }
    // Normalize the keyword and split into word bag
    const normalizedKeyword = removeAccents(kw);
    const keywordWordBag = normalizedKeyword.split(' ');
    const keywordLength = keywordWordBag.length;

    // Store the index of each word from the keyword occuring in the text
    let occurencesIdx = [];
    for (let i = 0; i < keywordWordBag.length; i++) {
      const word = keywordWordBag[i];
      const similarities = textWordBag
        .map((el, idx) => (checkSimilarity(word, el, 0.8) ? idx : -1))
        .filter((el) => el > -1);
      occurencesIdx.push(...similarities);
    }
    occurencesIdx.sort((a, b) => a - b);
    occurencesIdx = [...new Set(occurencesIdx)];
    // Get the occurence number of the keyword within the text
    // Method :
    // - Split the text into segment using the occurence indexes
    // - Match the keyword with the segment
    // - Take into account overlaping segmentation

    let lastOccurenceId = -1;
    let occurenceCount = 0;
    for (let i = 0; i < occurencesIdx.length; i++) {
      // Text segment start index
      const start = occurencesIdx[i];

      // If overlaping, continue
      if (start < lastOccurenceId) {
        continue;
      }
      for (let j = i; j < occurencesIdx.length; j++) {
        // Text segment end index
        const end =
          j + 1 < occurencesIdx.length && !(j == i) ? occurencesIdx[j + 1] : keywordLength == 1 ? start : start + 1;

        // Speed improvement : if the segment is too long, skip it
        if (end - start > keywordLength * 3) {
          break;
        }
        // Text segment
        const textSeg = getTextSeg(textWordBag, start, end);

        // Check if the keyword is in the segment
        const similarity = checkSimilarity(normalizedKeyword, textSeg, 0.8);
        // If overlaping to previous, ignore
        if (similarity && end - lastOccurenceId > 2) {
          lastOccurenceId = end;
          occurenceCount++;
          break;
        }
      }
    }
    return occurenceCount;

    function getTextSeg(array, start, end) {
      return array.slice(start, end + 1).join(' ');
    }
  }
}
export const formatResumeAnalyse = (data) => {
  let filteredData = data;
  if (filteredData.some((sk) => sk.priority == true)) {
    filteredData = filteredData.filter((sk) => sk.priority == true);
  }
  let hardSkills = filteredData.reduce(function (filtered, skills) {
    if (
      [
        'skill_Hard_skill',
        'skill_Technical_skill',
        'skill_Tools',
        'skill_Specializations',
        'skill_Management',
        'skill_Techniques',
        'skill_Strategies',
        'skill_Processes',
      ].includes(skills.skillType) &&
      skills.skill != undefined
    ) {
      filtered.push(skills.skill);
    }
    return filtered;
  }, []);

  let softSkills = filteredData.reduce(function (filtered, skills) {
    if (['skill_Soft_skill'].includes(skills.skillType) && skills.skill != undefined) {
      filtered.push(skills.skill);
    }
    return filtered;
  }, []);

  return { softSkills, hardSkills };
};

const feedbackArray = [
  {
    Candidate_Level: 'Entry-Level',
    Applied_Role_Level: 'Mid-Level',
    Feedback_slug: 'entry_mid_level_feedback',
    Feedback_Message:
      'You are applying to a mid-level role. This role may require more experience than you currently possess. We recommend seeking roles that better match your experience level to boost your chances of success. This feedback is for your guidance and does not impact your match rate.',
  },
  {
    Candidate_Level: 'Entry-Level',
    Applied_Role_Level: 'Senior',
    Feedback_slug: 'entry_senior_level_feedback',
    Feedback_Message:
      'You are aspiring to a senior role, which typically requires extensive professional experience. Given your current entry-level status, we suggest focusing on opportunities that are more aligned with your career stage. This will help you build the necessary experience and does not affect your match rate.',
  },
  {
    Candidate_Level: 'Mid-Level',
    Applied_Role_Level: 'Senior',
    Feedback_slug: 'mid_senior_level_feedback',

    Feedback_Message:
      'It appears you are applying for a senior position, yet your experience is more aligned with mid-level opportunities. We recommend targeting roles that correspond more closely with your professional journey to improve your job search effectiveness. This feedback is provided to help refine your applications and does not affect your match rate.',
  },
  {
    Candidate_Level: 'Mid-Level',
    Applied_Role_Level: 'Entry-Level',
    Feedback_Message:
      'Your application for an entry-level position has been noted. With your mid-level experience, you might find more challenges and rewards in roles that fully utilize your skills. We recommend applying to positions that match your current career level to enhance your job satisfaction and growth. This advice does not impact your match rate.',
    Feedback_slug: 'mid_entry_level_feedback',
  },
  {
    Candidate_Level: 'Senior',
    Applied_Role_Level: 'Mid-Level',
    Feedback_Message:
      'Applying to a mid-level role when you possess senior-level experience could underutilize your skills and experience. We recommend exploring opportunities that match your advanced expertise, as they are likely to offer greater professional fulfillment and career advancement. This feedback is aimed at helping you target more suitable positions and does not affect your match rate.',
    Feedback_slug: 'senior_mid_level_feedback',
  },
  {
    Candidate_Level: 'Senior',
    Applied_Role_Level: 'Entry-Level',
    Feedback_slug: 'senior_entry_level_feedback',
    Feedback_Message:
      'Your application for an entry-level position has been noted. However, with your extensive senior-level experience, you may find more fulfilling opportunities at a higher level. Targeting positions that match your expertise could enhance your career progression. This advice is aimed at optimizing your job search and does not influence your match rate.',
  },
];

const entryLevelArray = [
  { synonym: 'entrylevel', word: 'Entry-Level' },
  { synonym: 'beginner', word: 'Entry-Level' },
  { synonym: 'novice', word: 'Entry-Level' },
  { synonym: 'junior', word: 'Entry-Level' },
  { synonym: 'trainee', word: 'Entry-Level' },
  { synonym: 'intern', word: 'Entry-Level' },
];

const midLevelArray = [
  { synonym: 'intermediate', word: 'Mid-Level' },
  { synonym: 'experienced', word: 'Mid-Level' },
  { synonym: 'middle', word: 'Mid-Level' },
  { synonym: 'seasoned', word: 'Mid-Level' },
  { synonym: 'proficient', word: 'Mid-Level' },
  { synonym: 'midlevel', word: 'Mid-Level' },
];

const seniorLevelArray = [
  { synonym: 'advanced', word: 'Senior' },
  { synonym: 'expert', word: 'Senior' },
  { synonym: 'veteran', word: 'Senior' },
  { synonym: 'lead', word: 'Senior' },
  { synonym: 'principal', word: 'Senior' },
  { synonym: 'chief', word: 'Senior' },
  { synonym: 'senior', word: 'Senior' },
];

export function normalizeString(str) {
  return str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
}

function getLevelFromText(text) {
  const normalizeText = normalizeString(text);
  for (let entry of entryLevelArray) {
    if (normalizeText?.includes(normalizeString(entry.synonym))) {
      return entry.word;
    }
  }
  for (let mid of midLevelArray) {
    if (normalizeText?.includes(normalizeString(mid.synonym))) {
      return mid.word;
    }
  }
  for (let senior of seniorLevelArray) {
    if (normalizeText?.includes(normalizeString(senior.synonym))) {
      return senior.word;
    }
  }
  return null; // Return null if no match is found
}

export function getFeedback(resumeJobtext, appliedJobText, t) {
  const candidateLevel = getLevelFromText(resumeJobtext);
  const appliedRoleLevel = getLevelFromText(appliedJobText);
  const jobLevelMacthLabel = t('analyse.job.level.title');
  if (candidateLevel && appliedRoleLevel && checkSimilarity(candidateLevel, appliedRoleLevel) == true) {
    return { checked: true, text: t('analyse.job.level.match'), title: jobLevelMacthLabel };
  }
  if (appliedRoleLevel == 'Mid-Level' && !candidateLevel) {
    return { checked: true, text: t('analyse.job.level.match'), title: jobLevelMacthLabel };
  }
  if (!candidateLevel && appliedRoleLevel) {
    return {
      checked: false,
      fieldName: 'title',
      text: replaceString(t('analyse.job.level.missingjob'), {
        '{appliedRoleLevel}': `<b>${appliedRoleLevel}</b>`,
      }),
      title: jobLevelMacthLabel,
    };
  }

  if (!appliedRoleLevel && candidateLevel) {
    return { checked: true, text: t('analyse.job.level.not.specify'), title: jobLevelMacthLabel };
  }

  const feedback = feedbackArray.find(
    (item) => item.Candidate_Level === candidateLevel && item.Applied_Role_Level === appliedRoleLevel,
  );
  const message = feedback?.Feedback_slug && t(`analyse.feedback.${feedback.Feedback_slug}`);
  return {
    checked: false,
    fieldName: 'title',
    text: feedback ? (message && message) || feedback.Feedback_Message : 'No feedback available for the given levels.',
    title: jobLevelMacthLabel,
  };
}

export const RESUME_BULLET_EXP_VARIANTS_DESKTOP = ['with_multiline_bullet', 'with_bullet'];

export const getSearchTemplateName = (resumes) => {
  if (!resumes) return;
  const searchTemplate = resumes?.find((data) => data?.agentSearchId && !data?.agentJobId);
  return searchTemplate ? searchTemplate?.name : null;
};
export const getJobTemplateName = (resumes) => {
  if (!resumes) return;
  const searchTemplate = resumes?.find((data) => data?.agentJobId);
  return searchTemplate ? searchTemplate?.name : null;
};

export const percentage = (maxValue, value) => {
  return (parseFloat(value) * 100) / parseFloat(maxValue);
};

export const customTemplateImage = {
  atsresume: { default: 'img/ATSResumeWithProfile.png' },
};

export const isSAResume = (resume) => {
  if (resume?.saVersion || resume?.agentJobId || resume?.agentSearchId) return true;
  return false;
};

export const isDomainInCountry = (domain, country) => {
  const entry = FREE_CHECKOUT_DOMAIN.find((item) => item.domain === domain);
  return entry ? entry.country.includes(country) : false;
};

export const CALI_LIMA_EXP_MOBILE_WON_LOCALE = ['en', 'fr', 'tr', 'es', 'ar', 'hr', 'hu'];
export const CALI_LIMA_EXP_DESKTOP_WON_LOCALE = ['en', 'fr', 'tr', 'es', 'ar', 'hr', 'hu'];

export const CALI_LIMA_EXP_DESKTOP_LOST_LOCALE = ['pt-br', 'ru'];
export const CALI_LIMA_EXP_MOBILE_LOST_LOCALE = ['pt-br', 'ru'];

export const ACTIVE_BRUNEI_EXP_MOBILE_LOCALE = ['pt-br'];
export const ACTIVE_BRUNEI_EXP_DESKTOP_LOCALE = ['es'];

export const BRUNEI_EXP_MOBILE_WON_LOCALE = ['es', 'ar'];
export const BRUNEI_EXP_DESKTOP_WON_LOCALE = [''];

export const BRUNEI_DESIGN_EXP_WON_MOBILE_LOCALE = [''];
export const BRUNEI_DESIGN_EXP_WON_DESKTOP_LOCALE = [''];

export const NEW_CHECKOUT_EXP_MOBILE_TEST_LOCALE = ['tr'];
export const NEW_CHECKOUT_EXP_DESKTOP_TEST_LOCALE = ['ka'];

export const ACTIVE_NEW_CHECKOUT_EXP_MOBILE_LOCALE = [
  'pt-br',
  'ru',
  'uk',
  'cs',
  'hu',
  'ro',
  'ar',
  'lv',
  'hr',
  'pl',
  'lt',
  'ka',
  'pt',
  'de',
  'id',
  'sv',
];

export const ACTIVE_NEW_CHECKOUT_EXP_DESKTOP_LOCALE = [''];

export const CHECKOUT_PREVIEW_EXP_DESKTOP_WON_LOCALE = [
  'en',
  'sv',
  'hr',
  'ro',
  'uk',
  'hu',
  'cs',
  'lv',
  'pl',
  'lt',
  'ka',
  'pt',
  'de',
];

export const ACTIVE_NEW_CHECKOUT_V2_EXP_DESKTOP_LOCALE = ['id', 'lv', 'pl', 'lt', 'pt'];

export const NEWLY_CHECKOUT_WON_HOST_DESKTOP = ['melhorcv'];

export const FIXED_DESIGN_MOBILE_WON_LOCALE = ['es', 'fr'];

export const NEW_DESIGN_MOBILE_WON_LOCALE = [
  'az',
  'no',
  'sr',
  'et',
  'th',
  'it',
  'zh-cn',
  'da',
  'ko',
  'vi',
  'el',
  'nl',
  'he',
  'ms',
  'ca',
  'kk',
  'hi',
  'bn',
  'tl',
  'ja',
  'fa',
  'tj',
  'en',
];

export const NEWLY_CHECKOUT_EXP_DESKTOP_WON_LOCALE = [
  'es',
  'en',
  'fr',
  'tr',
  'az',
  'no',
  'sr',
  'et',
  'th',
  'it',
  'zh-cn',
  'da',
  'ko',
  'vi',
  'el',
  'nl',
  'he',
  'ms',
  'ca',
  'kk',
  'hi',
  'bn',
  'tl',
  'ja',
  'fa',
  'tj',
  'uz',
  'ar',
  'hr',
  'ru',
  'uk',
  'cs',
  'ro',
  'de',
  'hu',
  'sv',
];

export const NEWLY_EXP_SIDE_CHANGE_EXP_LOCALE_DESKTOP = ['fr', 'es', 'en', 'tr'];
export const NEWLY_EXP_SIDE_CHANGE_EXP_WON_LOCALE_DESKTOP = [''];

export const OLD_CONTROL_VS_NEWLY_RTL_EXP_LOCALE_DESKTOP = ['pt-br'];
export const OLD_CONTROL_VS_NEWLY_RTL_EXP_WON_LOCALE_DESKTOP = [''];

export const NEW_CHECKOUT_EXP_MOBILE_WON_HOST = ['melhorcv'];

export const HIDE_BRUNEI_OPTION_EXP_DESKTOP_LOCALE = ['es'];

export const HERO_SECTION_EXP_LOCALE = ['en', 'ar', 'bn', 'bg', 'tl', 'kk', 'ms', 'tj', 'tr', 'uk', 'uz'];

export const RTL_NEW_DESIGN_EXP_ACTIVE_LOCALE = ['ar'];

export const getPostHogDistinctId = () => {
  const data = cookieParser();
  const postHogData = data?.[`ph_${env.POSTHOG_KEY}_posthog`];
  if (postHogData) {
    let parseData = JSON.parse(postHogData);
    return parseData?.distinct_id;
  }
  return null;
};

export const ALL_ACTIVE_LOCALE_ATSRESUME = ['en', 'fr', 'es', 'tr'];

export const ACTIVE_ATSRESUME_TEMPLATE_TEST_LOCALE_DESKTOP = ['fr', 'es', 'tr'];
export const ATS_WITHOUT_PHOTO_TEMPLATE_WON_LOCALE_DESKTOP = ['en'];
export const ATS_WITH_PHOTO_TEMPLATE_WON_LOCALE_DESKTOP = [''];
export const ATS_CONTROL_TEMPLATE_WON_LOCALE_DESKTOP = [''];

export const ACTIVE_ATSRESUME_TEMPLATE_TEST_LOCALE_MOBILE = ['fr', 'es', 'tr'];
export const ATS_WITHOUT_PHOTO_TEMPLATE_WON_LOCALE_MOBILE = [''];
export const ATS_WITH_PHOTO_TEMPLATE_WON_LOCALE_MOBILE = ['en'];
export const ATS_CONTROL_TEMPLATE_WON_LOCALE_MOBILE = [''];

export const ACTIVE_ATS_RESUME_NEW_DESIGN_EXP_DESKTOP_LOCALE = ['en', 'fr', 'es', 'tr'];
export const ACTIVE_ATS_RESUME_NEW_DESIGN_EXP_MOBILE_LOCALE = ['en', 'fr', 'es', 'tr'];
export const WIN_ATS_RESUME_NEW_DESIGN_EXP_DESKTOP_LOCALE = [''];
export const WIN_ATS_RESUME_NEW_DESIGN_EXP_MOBILE_LOCALE = [''];

export const getAIPopupCTASlug = (locale) => {
  const LOCALES_WITH_APPLIED_VARIANT1 = ['tr', 'hr', 'ro', 'id', 'et'];
  const LOCALES_WITH_APPLIED_VARIANT2 = ['fr', 'en', 'es', 'ru', 'pl', 'pt-br', 'zh-cn', 'ka'];
  const LOCALES_WITH_APPLIED_VARIANT3 = ['ar', 'lv', 'hu', 'lt'];
  const LOCALES_WITH_APPLIED_VARIANT4 = ['cs', 'de'];

  switch (true) {
    case LOCALES_WITH_APPLIED_VARIANT1.includes(locale):
      return 'generator_ai_suggestion_button_text1';
    case LOCALES_WITH_APPLIED_VARIANT3.includes(locale):
      return 'generator_ai_suggestion_button_text3';
    case LOCALES_WITH_APPLIED_VARIANT4.includes(locale):
      return 'generator_ai_suggestion_button_text4';
    case LOCALES_WITH_APPLIED_VARIANT2.includes(locale):
    default:
      return 'generator_ai_suggestion_button_text2';
  }
};
